import React from "react";
import DataTable from "react-data-table-component";
import { FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN } from "../common/DisplayMsgAndLabelConst";

export class SurveyFreeTextAnswersTableComponent extends React.Component<any> {
  private dataTableComponentKey: number = 1;
  state = {
    rowsPerPage: this.props.answerTableSettings.rowsPerPage,
    defaultPage: this.props.answerTableSettings.defaultPage,
  };

  componentDidUpdate = (prevProps: any) => {
    if (this.props.answerTableSettings.rowsPerPage !== this.state.rowsPerPage
      || this.props.answerTableSettings.defaultPage !== this.state.defaultPage) {
      this.setState({
        rowsPerPage: this.props.answerTableSettings.rowsPerPage,
        defaultPage: this.props.answerTableSettings.defaultPage
      });
      //This will forefully re-create the DataTable component
      this.dataTableComponentKey++;
    }
  };

  render() {
    return (
      <div className="table-responsive dt-responsive p-2">
        {this.props.openEndedAnswerArray !== null ? 
        <DataTable
        paginationDefaultPage={this.state.defaultPage}
        onChangePage={(page: number, totalRows: number) => {
          this.state.defaultPage = page;
          this.props.onChangePage(page);
        }}
        key={this.dataTableComponentKey}
        onChangeRowsPerPage={(
          currentRowsPerPage: number,
          currentPage: number
        ) => {
          this.state.rowsPerPage = currentRowsPerPage;
          this.props.onChangeRowsPerPage(currentRowsPerPage);
        }}
        columns={[
          {
            name: FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN(),
            selector: (row: any) => row.answer_text,
          },
        ]}
        data={this.props.openEndedAnswerArray}
        pagination
        paginationPerPage={this.state.rowsPerPage}
        paginationRowsPerPageOptions={
          this.props.answerTableSettings.rowsPerPageOptions
        }
      /> : null }
        
      </div>
    );
  }
}
export default SurveyFreeTextAnswersTableComponent;
