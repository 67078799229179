import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AnswerSelectionComponent } from "./AnswerSelectionComponent";
import { Checkbox } from "@mui/material";
import { ATLEAST_ONE_ANSWER_MSG,
  ANSWER_FILTER_BAR_SEARCH_PLACEHOLDER } from "../common/DisplayMsgAndLabelConst";
import Highlighter from "react-highlight-words";



export class AnswerFilterBar extends React.Component<any> {
  state = {
    searchText: "",
    allOptionsSelected: new Map(),
    allOptionsUnSelected: new Map(),
    atleastOneOptionSelected: new Map(),
  };

  handleChange = (questionId: number, answerId: number) => {
    this.props.handleSelectChange(questionId, answerId);
    this.setOptionsSelectedStatusMaps(
      questionId,
      this.getSelectedOptionsCount(questionId)
    );
    this.setState({});
  };

  setOptionsSelectedStatusMaps = (
    questionId: number,
    selectedOptionCount: number
  ) => {
    this.state.allOptionsSelected.set(
      questionId,
      this.props.selectedFilter.get(questionId).size === selectedOptionCount
    );
    this.state.allOptionsUnSelected.set(questionId, selectedOptionCount === 0);
    this.state.atleastOneOptionSelected.set(
      questionId,
      selectedOptionCount > 0
    );
  };

  getSelectedOptionsCount = (questionId: number): number => {
    let selectedOptionCount: number = 0;
    this.props.selectedFilter
      .get(questionId)
      .forEach((value: boolean, key: number) => {
        if (value === true) {
          selectedOptionCount++;
        }
      });
    return selectedOptionCount;
  };

  componentDidMount = () => {
    this.props.selectedFilter.forEach((answerMap: any, questionId: number) => {
      this.state.allOptionsSelected.set(questionId, true);
      this.state.atleastOneOptionSelected.set(questionId, true);
      this.state.allOptionsUnSelected.set(questionId, false);
    });
    this.setState({});
  };

  handleResetAnswerSelection = (questionId: number) => {
    let checkedStatus: boolean = !this.state.allOptionsSelected.get(questionId);
    this.state.allOptionsSelected.set(questionId, checkedStatus);
    this.state.allOptionsUnSelected.set(questionId, !checkedStatus);
    this.state.atleastOneOptionSelected.set(questionId, checkedStatus);
    this.props.handleResetAnswerSelection(questionId, checkedStatus);
    this.setState({});
  };

  componentDidUpdate = (prevProps: any) => {
    if (
      prevProps.selectedFilterAccordionQuestionId !=
      this.props.selectedFilterAccordionQuestionId
    ) {
      const element: any = document.getElementById(
        "accordion" + this.props.selectedFilterAccordionQuestionId
      );
      if (element != null) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }

    //To handle Load Analysis Button click
    //Checking whether any changes in the object reference
    if (prevProps.selectedFilter !== this.props.selectedFilter) {
      this.refreshOptionsSelectedStatusMaps();
    }
  };

  refreshOptionsSelectedStatusMaps = () => {
    this.props.selectedFilter.forEach((answerMap: any, questionId: number) => {
      this.setOptionsSelectedStatusMaps(
        questionId,
        this.getSelectedOptionsCount(questionId)
      );
    });
    this.setState({});
  };

  handleSearchTextInputChange = (event: any) => {
    this.setState({ searchText: event.target.value });
  };

  isTextExistsInQuestionOrAnswers = (
    searchText: string,
    questionId: number
  ): boolean => {
    if (searchText === null || searchText.trim().length == 0) {
      return true;
    }
    let questionObj: any = this.props.questionVsAnswerMap.get(questionId);
    if (
      questionObj.question.question_text
        .toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      return true;
    }

    let answerObjects: any = Array.from(questionObj.answerMap.values());

    for (let i = 0; i < answerObjects.length; i++) {
      if (
        answerObjects[i].selectedAnswerText
          .toLowerCase()
          .includes(searchText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  };

  clearInput = () =>{
    this.setState({ searchText: '' });
  }

  render() {
    return (
      <div>
        <div className="input-group mb-3">
          <input
            value={this.state.searchText}
            type="text"
            className="form-control"
            placeholder={ANSWER_FILTER_BAR_SEARCH_PLACEHOLDER()}
            id="searchTextInput"
            onChange={this.handleSearchTextInputChange}
            aria-label="Amount (to the nearest dollar)"
          />
          <div className="input-group-append">
            <span className="input-group-text pointer"
            onClick={this.clearInput}
            >X</span>
          </div>
        </div>

        {this.props.questionVsAnswerMap
          ? Array.from(this.props.questionVsAnswerMap.values()).map(
              (questionVsAnswerEntry: any, index: any) => {
                return questionVsAnswerEntry.question.question_type ===
                  "single_punch" ||
                  questionVsAnswerEntry.question.question_type ===
                    "multi_punch" ? (
                  this.isTextExistsInQuestionOrAnswers(
                    this.state.searchText,
                    questionVsAnswerEntry.question.id
                  ) ? (
                    <Accordion
                      id={`accordion${questionVsAnswerEntry.question.id}`}
                      key={index}
                      expanded={
                        this.props.selectedFilterAccordionQuestionId ==
                        questionVsAnswerEntry.question.id
                      }
                      onChange={(event: any, expanded: boolean) => {
                        this.props.toggleQuestionAccordionHandler(
                          questionVsAnswerEntry.question.id,
                          expanded
                        );
                      }}
                    >
                      <AccordionSummary
                        className={`${
                          this.state.atleastOneOptionSelected.get(
                            questionVsAnswerEntry.question.id
                          ) === true &&
                          this.state.allOptionsSelected.get(
                            questionVsAnswerEntry.question.id
                          ) === false
                            ? "bg-primary text-white"
                            : ""
                        }`}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`question${index}`}
                      >
                        <div>
                          <span
                            className={`d-block ${
                              this.state.allOptionsUnSelected.get(
                                questionVsAnswerEntry.question.id
                              ) === true
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            <Highlighter
                              searchWords={[this.state.searchText]}
                              autoEscape={true}
                              textToHighlight={
                                questionVsAnswerEntry.question.question_text
                              }
                            />
                          </span>
                          {this.state.allOptionsUnSelected.get(
                            questionVsAnswerEntry.question.id
                          ) === true ? (
                            <span className="text-danger d-block">
                              {" " + ATLEAST_ONE_ANSWER_MSG() + " "}
                            </span>
                          ) : null}

                          <div className="col-md-12">
                            <Checkbox
                              checked={
                                this.state.allOptionsSelected.get(
                                  questionVsAnswerEntry.question.id
                                ) === true
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                this.handleResetAnswerSelection(
                                  questionVsAnswerEntry.question.id
                                );
                                e.stopPropagation();
                              }}
                            />
                            <label className="form-check-label">
                              All
                            </label>
                          </div>
                        </div>
                      </AccordionSummary>
                      {questionVsAnswerEntry.question.answers
                        ? questionVsAnswerEntry.question.answers.map(
                            (answersItem: any, index: any) => (
                              <AnswerSelectionComponent
                                key={index}
                                searchText={this.state.searchText}
                                isSelected={this.props.selectedFilter
                                  .get(questionVsAnswerEntry.question.id)
                                  .get(answersItem.id)}
                                answersItem={answersItem}
                                index={index}
                                handleChange={(answerId: any) => {
                                  this.handleChange(
                                    questionVsAnswerEntry.question.id,
                                    answerId
                                  );
                                }}
                              />
                            )
                          )
                        : null}
                    </Accordion>
                  ) : null
                ) : null;
              }
            )
          : null}
      </div>
    );
  }
}
