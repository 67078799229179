import React from "react";
import SpinnerLoader from "../../commonCommponent/spinner/SpinnerLoader";
import { SurveyInfoRowComponent } from "./SurveyInfoRowComponent";
import { ScrollSync } from "react-scroll-sync";
import ClipLoader from "react-spinners/ClipLoader";
import Collapse from "react-bootstrap/Collapse";
import { ScrollSyncPane } from "react-scroll-sync";
import { FullPageError } from "../common/FullPageError";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//https://fkhadra.github.io/react-toastify/introduction/

import { CHART_COLOR_CODE } from "../common/DisplayMsgAndLabelConst";

import {
  ANSWER_JSON_PARSE_ERROR,
  INVALID_PROJECT_ID_IN_QUERY_PARAM,
  MIN_COULUMN_ERROR,
  NO_ANSWER_DATA_FOUND,
  NO_QUESTION_DATA_FOUND,
  SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR,
  SAVE_ANALYSIS_EMPTY_ERROR,
  DASBOARD_SAVE_SUCCESS_BEGINNING_TEXT,
  DASBOARD_SAVE_SUCCESS_END_TEXT,
  COLLAPSE_BUTTON,
  FILTER_BUTTON,
  DASBOARD_NEW_COMPARISION_CREATED_SUCCESS,
  DASBOARD_LOAD_SUCCESS_END_TEXT,
  DASBOARD_LOAD_SUCCESS_BEGINNING_TEXT,
  DASBOARD_LOAD_ANALYSIS_DATA_ERROR,
  DASBOARD_FILTERS_TITLE,
} from "../common/DisplayMsgAndLabelConst";
import { SideNavBarList } from "./SideNavBarList";
import {
  AnswerDetailsDialog,
  INVOCATION_MODE_ANSWER,
  INVOCATION_MODE_QUESTION,
} from "./ModalDialogs/AnswerDetailsDialog";
import { SaveSurveyDialog } from "./ModalDialogs/SaveSurveyDialog";
import { LoadSurveyDialog } from "./ModalDialogs/LoadSurveyDialog";
import { SurveyQuickViewTableComponent } from "./SurveyQuickViewTableComponent";
import { SettingsDialog } from "./ModalDialogs/SettingsDialog";
import ReactTooltip from "react-tooltip";
import Toolbar from "../../layout/mainlayout/Toolbar";
import { TEST_HASH_PARAM } from "../common/ConfigConst";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Box } from "@mui/material";
import { QuestionsListDialog } from "./ModalDialogs/QuestionsListDialog";

export class SurveyAnalysisDahboard extends React.Component<any> {
  GET_QUESTIONS_URL: any =
    "https://askv2.makeopinion.com/?page=reporting-backend-questions" +
    TEST_HASH_PARAM();

  GET_ANSWERS_URL: any =
    "https://askv2.makeopinion.com/?page=reporting-backend-answers" +
    TEST_HASH_PARAM();

  SAVE_ANALYSIS_URL: any =
    "https://askv2.makeopinion.com/?page=reporting-backend-analysis-save" +
    TEST_HASH_PARAM();

  GET_ANALYSIS_LIST_URL: any =
    "https://askv2.makeopinion.com/?page=reporting-backend-analysis-get-by-projectid" +
    TEST_HASH_PARAM();

  private sideNavBarListDivRef: any;
  private sideNavBarListChildRef: any;
  private loadSurveyDialogChildRef: any;

  constructor(props: any) {
    super(props);
    this.sideNavBarListDivRef = React.createRef();
    this.sideNavBarListChildRef = React.createRef();
    this.loadSurveyDialogChildRef = React.createRef();
  }

  state = {
    projectId: null,
    selectedColumnId: -1,
    selectedFilterAccordionQuestionId: -1,
    columnVsFilterMap: new Map(),
    allAnswerMap: new Map(),
    columnVsQuestionVsAnswerMap: new Map(),
    columnVsRestrictedUserIds: new Map(),
    allUserAnswers: [],
    answerIdVsUserIdsMap: new Map(),
    scrollPage: "overflow-auto",
    switchStatus: true,
    sideNavView: "none",
    isSideNavBarOpen: false,
    isLoadAnalysisButtonEnabled: false,
    loading: false,
    settings: {
      answerTable: {
        updateRequired: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 100],
        displaySamePageAllColumns: false,
      },
      diagram: {
        displayFilteredValue: false,
      },
    },
    showSettingModal: false,
    answerDetailModal: {
      selectedQuestionId: -1,
      selectedAnswerIds: [],
      show: false,
      answerTableData: null,
      questionVsAnswerEntry: null,
      invocationMode: null,
    },
    saveAnalysisModal: {
      errorMessage: null,
      show: false,
      analysisName: "",
    },
    loadAnalysisModal: {
      show: false,
      showCreateNew: false,
      savedFilters: null,
    },
    quickViewMap: new Map(),
    quickViewOpen: true,
    showQuestionListModal: false,
    showProjectIdError: false,
    currentQuestionScrollPos: 0,
  };

  resetStateVariables = () => {
    //this.state.projectId = null;
    this.state.selectedColumnId = -1;
    this.state.selectedFilterAccordionQuestionId = -1;
    this.state.columnVsFilterMap = new Map();
    //this.state.allAnswerMap = new Map();
    this.state.columnVsQuestionVsAnswerMap = new Map();
    this.state.columnVsRestrictedUserIds = new Map();
    //this.state.allUserAnswers = [];
    //this.state.answerIdVsUserIdsMap = new Map();
    this.state.scrollPage = "overflow-auto";
    this.state.switchStatus = true;
    this.state.sideNavView = "none";
    this.state.isSideNavBarOpen = false;
    // this.state.isLoadAnalysisButtonEnabled =
    //   this.isSurveyDataExistsInLocalStorage();
    this.state.loading = false;
  };

  componentDidMount() {
    let isParamsLoaded: boolean = this.loadURLParams();
    if (!isParamsLoaded) {
      return;
    }
    document.addEventListener("click", this.handleClickOutside, true);
    window.addEventListener("scroll", this.handleScroll);
    this.fetchAllQuestions(null, null);
    this.showLoadAnalysisDialogIfDataExists();
  }

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (this.state.settings.answerTable.updateRequired === true) {
      this.state.settings.answerTable.updateRequired = false;
    }
  };

  showLoadAnalysisDialogIfDataExists = () => {
    this.loadSavedAnalysisData((savedFilters: any) => {
      let showDialog: boolean =
        savedFilters !== null && savedFilters.length > 0;
      this.setState({
        loadAnalysisModal: {
          show: showDialog,
          savedFilters: savedFilters,
          showCreateNew: showDialog,
        },
      });
    });
  };

  loadSavedAnalysisData = (callbackFn: Function) => {
    fetch(this.GET_ANALYSIS_LIST_URL + "&project_id=" + this.state.projectId)
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          return null;
        }
      })
      .then((savedFilters) => {
        callbackFn(savedFilters);
      });
  };

  isScrolledIntoQuestionView = (questionId: any): boolean => {
    let elem: any = document.getElementById("questionRowDiv" + questionId);
    var docViewTop = window.pageYOffset;
    var docViewBottom = docViewTop + window.innerHeight;
    var elemTop = elem.offsetTop;
    var elemBottom = elemTop + elem.offsetHeight;
    if (docViewBottom < elemTop || docViewTop > elemBottom) {
      return false;
    }
    return true;
  };

  handleScroll = (event: any) => {
    let questionIds = Array.from(
      this.state.columnVsQuestionVsAnswerMap
        .get(this.state.selectedColumnId)
        .keys()
    );

    for (let i = 0; i < questionIds.length; i++) {
      if (this.isScrolledIntoQuestionView(questionIds[i])) {
        this.state.currentQuestionScrollPos = i;
        break;
      }
    }
  };

  handleClickOutside = (event: any) => {
    if (this.state.isSideNavBarOpen === false) {
      return;
    }

    let elementId: string =
      event.target !== null && event.target !== undefined
        ? event.target.id
        : null;

    if (
      elementId !== null &&
      elementId !== undefined &&
      elementId.includes("filterButton")
    ) {
      return;
    }

    if (
      this.sideNavBarListDivRef.current &&
      !this.sideNavBarListDivRef.current.contains(event.target)
    ) {
      this.setState({ isSideNavBarOpen: false });
    }
  };

  getSerializedFilterMap = () => {
    let columnVsFilterMap: any = {};
    this.state.columnVsFilterMap.forEach((filterMap: any, columnId: any) => {
      columnVsFilterMap[columnId] = {};
      filterMap.forEach((answerMap: any, questionId: any) => {
        columnVsFilterMap[columnId][questionId] = {};
        answerMap.forEach((isSelected: any, answerId: any) => {
          columnVsFilterMap[columnId][questionId][answerId] = isSelected;
        });
      });
    });
    return columnVsFilterMap;
  };

  getDeSerializedFilterMap = (columnVsFilterObj: any) => {
    if (columnVsFilterObj == null) {
      return null;
    }
    let columnVsFilterMap: Map<number, any> = new Map();
    for (var columnId in columnVsFilterObj) {
      let columnIdNumber: number = parseInt(columnId);
      if (columnVsFilterMap.get(columnIdNumber) == null) {
        columnVsFilterMap.set(columnIdNumber, new Map());
      }
      for (var questionId in columnVsFilterObj[columnId]) {
        if (columnVsFilterMap.get(columnIdNumber).get(questionId) == null) {
          columnVsFilterMap.get(columnIdNumber).set(questionId, new Map());
        }
        for (var answerId in columnVsFilterObj[columnId][questionId]) {
          columnVsFilterMap
            .get(columnIdNumber)
            .get(questionId)
            .set(answerId, columnVsFilterObj[columnId][questionId][answerId]);
        }
      }
    }
    return columnVsFilterMap;
  };

  toggleSaveAnalysis = () => {
    this.setState({
      saveAnalysisModal: { show: !this.state.saveAnalysisModal.show },
    });
  };

  toggleLoadAnalysis = () => {
    this.state.loadAnalysisModal.showCreateNew = false;
    if (this.state.loadAnalysisModal.show) {
      this.setState({
        loadAnalysisModal: {
          show: false,
          savedFilters: null,
        },
      });
    } else {
      this.loadSavedAnalysisData((savedFilters: any) => {
        this.setState({
          loadAnalysisModal: {
            show: true,
            savedFilters: savedFilters,
          },
        });
      });
    }
  };

  handleSaveAnalysisButtonClick = () => {
    this.setState({ loading: true });
    if (
      this.state.saveAnalysisModal.analysisName !== null &&
      this.state.saveAnalysisModal.analysisName !== "" &&
      this.state.saveAnalysisModal.analysisName !== undefined
    ) {
      if (this.state.saveAnalysisModal.analysisName.length > 50) {
        this.setState({
          loading: false,
          saveAnalysisModal: {
            errorMessage: SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR(),
            show: true,
          },
        });
        return;
      } else {
        this.setState({ saveAnalysisModal: { errorMessage: "" } });
      }
    } else {
      this.setState({
        loading: false,
        saveAnalysisModal: {
          errorMessage: SAVE_ANALYSIS_EMPTY_ERROR(),
          show: true,
        },
      });
      return;
    }

    let surveyAnalysisDahboardData: any = {
      columnVsFilterMap: this.getSerializedFilterMap(),
      settings: this.state.settings,
      analysisName: this.state.saveAnalysisModal.analysisName,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(surveyAnalysisDahboardData),
    };
    fetch(
      this.SAVE_ANALYSIS_URL + "&project_id=" + this.state.projectId,
      requestOptions
    ).then((response) => {
      try {
        this.showMessage(
          "success",
          DASBOARD_SAVE_SUCCESS_BEGINNING_TEXT() +
            " " +
            surveyAnalysisDahboardData.analysisName +
            " " +
            DASBOARD_SAVE_SUCCESS_END_TEXT()
        );
        this.setState({
          loading: false,
          saveAnalysisModal: { analysisName: "" },
        });
        this.refreshLoadSurveyDialogList();
      } catch (error) {
        return null;
      }
    });
  };

  handleLoadAnalysisButtonClick = (selectedLoadFilter: any) => {
    if ("columnVsFilterMap" in selectedLoadFilter) {
      this.state.loading = true;
      this.setState({});
      let columnVsFilterMap: any =
        this.loadSurveyAnalysisDahboardData(selectedLoadFilter);

      if (columnVsFilterMap != null && columnVsFilterMap.size > 0) {
        if (this.state.columnVsQuestionVsAnswerMap.size == 0) {
          this.showMessage("error", { DASBOARD_LOAD_ANALYSIS_DATA_ERROR });
          console.log(
            "this.state.columnVsQuestionVsAnswerMap is empty",
            this.state.columnVsQuestionVsAnswerMap
          );
          return;
        }
        let questionVsAnswerMap = this.state.columnVsQuestionVsAnswerMap
          .values()
          .next().value;
        let firstFilterMap = columnVsFilterMap.values().next().value;
        this.resetStateVariables();
        this.updateFilterMapForAnyDifferences(
          columnVsFilterMap,
          this.getUpdatedParamsInFilterMapByQuestionVsAnswerMap(
            firstFilterMap,
            questionVsAnswerMap
          )
        );

        this.constructSurveyDashBoardUsingSavedData(
          columnVsFilterMap,
          questionVsAnswerMap,
          () => {
            this.state.loading = false;
            this.setState({ loadAnalysisModal: { show: false } });
            this.showMessage(
              "success",
              DASBOARD_LOAD_SUCCESS_BEGINNING_TEXT() +
                " " +
                selectedLoadFilter.analysisName +
                " " +
                DASBOARD_LOAD_SUCCESS_END_TEXT()
            );
          }
        );
      }
    }

    if ("settings" in selectedLoadFilter) {
      this.setState({ settings: selectedLoadFilter.settings });
    }
  };

  updateFilterMapForAnyDifferences = (
    columnVsFilterMap: any,
    dataDiffernce: any
  ) => {
    if (dataDiffernce.questionVsAnswerIdsTobeAdded.size > 0) {
      columnVsFilterMap.forEach((filterMap: any, columnId: any) => {
        dataDiffernce.questionVsAnswerIdsTobeAdded.forEach(
          (answerIdSet: any, questionId: any) => {
            if (!filterMap.has(questionId)) {
              filterMap.set(questionId, new Map());
            }
            for (let anwerId of answerIdSet) {
              filterMap.get(questionId).set(anwerId, true);
            }
          }
        );
      });
    }

    if (dataDiffernce.questionSetsTobeRemoved.size > 0) {
      columnVsFilterMap.forEach((filterMap: any, columnId: any) => {
        dataDiffernce.questionSetsTobeRemoved.forEach(
          (answerIdSet: any, questionId: any) => {
            filterMap.delete(questionId);
          }
        );
      });
    }

    if (dataDiffernce.questionVsAnswerIdsTobeRemoved.size > 0) {
      columnVsFilterMap.forEach((filterMap: any, columnId: any) => {
        dataDiffernce.questionVsAnswerIdsTobeRemoved.forEach(
          (answerIdSet: any, questionId: any) => {
            for (let anwerId of answerIdSet) {
              filterMap.get(questionId).delete(anwerId);
            }
          }
        );
      });
    }
  };

  getUpdatedParamsInFilterMapByQuestionVsAnswerMap = (
    filterMap: any,
    questionVsAnswerMap: any
  ): any => {
    /********************* TEST DATA CODE STARTS ****************** */
    /*
    questionVsAnswerMap.set(1111111, {
      answerMap: new Map(),
    });
    questionVsAnswerMap.get(1111111).answerMap.set(121212121, {});
    questionVsAnswerMap.get(1111111).answerMap.set(121212122, {});
    questionVsAnswerMap.get(1111111).answerMap.set(121212123, {});
    questionVsAnswerMap.get("1").answerMap.set(111111111111, {});
    questionVsAnswerMap.delete("1");
    questionVsAnswerMap.get("139").answerMap.delete("427");
    */
    /********************* TEST CODE ENDS ****************** */

    let questionVsAnswerIdsTobeAdded: Map<any, Set<any>> = new Map();
    let questionSetsTobeRemoved: Map<any, Set<any>> = new Map();
    let questionVsAnswerIdsTobeRemoved: Map<any, Set<any>> = new Map();

    questionVsAnswerMap.forEach((questionObj: any, questionId: any) => {
      //Applicable only for optional type questions
      if (questionObj.answerMap != null) {
        if (!filterMap.has(questionId)) {
          if (!questionVsAnswerIdsTobeAdded.has(questionId)) {
            questionVsAnswerIdsTobeAdded.set(questionId, new Set());
          }
          questionObj.answerMap.forEach((answerObj: any, answerId: any) => {
            questionVsAnswerIdsTobeAdded.get(questionId)?.add(answerId);
          });
        } else {
          questionObj.answerMap.forEach((answerObj: any, answerId: any) => {
            if (!filterMap.get(questionId).has(answerId)) {
              if (!questionVsAnswerIdsTobeAdded.has(questionId)) {
                questionVsAnswerIdsTobeAdded.set(questionId, new Set());
              }
              questionVsAnswerIdsTobeAdded.get(questionId)?.add(answerId);
            }
          });
        }
      }
    });

    filterMap.forEach((answerMap: any, questionId: any) => {
      if (!questionVsAnswerMap.has(questionId)) {
        questionSetsTobeRemoved.set(questionId, new Set());
      } else {
        answerMap.forEach((value: any, answerId: any) => {
          if (!questionVsAnswerMap.get(questionId).answerMap.has(answerId)) {
            if (!questionVsAnswerIdsTobeRemoved.has(questionId)) {
              questionVsAnswerIdsTobeRemoved.set(questionId, new Set());
            }
            questionVsAnswerIdsTobeRemoved.get(questionId)?.add(answerId);
          }
        });
      }
    });

    return {
      questionVsAnswerIdsTobeAdded: questionVsAnswerIdsTobeAdded,
      questionSetsTobeRemoved: questionSetsTobeRemoved,
      questionVsAnswerIdsTobeRemoved: questionVsAnswerIdsTobeRemoved,
    };
  };

  loadSurveyAnalysisDahboardData = (surveyAnalysisDahboardData: any) => {
    if (surveyAnalysisDahboardData != null) {
      return this.getDeSerializedFilterMap(
        surveyAnalysisDahboardData["columnVsFilterMap"]
      );
    }
    return null;
  };

  constructSurveyDashBoardUsingSavedData = (
    columnVsFilterMap: any,
    copyQuestionVsAnswerMap: any,
    callBackHandler: any
  ) => {
    let columnIdsArray = Array.from(columnVsFilterMap.keys());

    for (let i = 0; i < columnIdsArray.length; i++) {
      let columnId: any = columnIdsArray[i];
      let questionVsAnswerFilterMap = columnVsFilterMap.get(columnId);
      this.state.selectedColumnId = columnId;
      this.state.columnVsFilterMap.set(columnId, questionVsAnswerFilterMap);
      let questionVsAnswerMap = new Map();
      let restrictedUserIdsMap = new Map();
      copyQuestionVsAnswerMap.forEach((questionObj: any, key: any) => {
        let newQuestionVsAnswerEntry = this.buildQuestionVsAnswerEntry(
          columnId,
          questionObj.question,
          null,
          null,
          null,
          0,
          null,
          null,
          null
        );
        questionVsAnswerMap.set(key, newQuestionVsAnswerEntry);
        this.state.columnVsQuestionVsAnswerMap.set(
          columnId,
          questionVsAnswerMap
        );
        this.updateRestrictedUserIdsByAnswerId();
        //Copying restricted user ids from selected restrictedUserIdsMap
        this.state.columnVsRestrictedUserIds
          .get(this.state.selectedColumnId)
          .forEach((value: any, key: any) => {
            restrictedUserIdsMap.set(key, value);
          });
      });
      this.processAllUserAnswersAndUpdateAnswerMap(
        questionVsAnswerMap,
        columnId
      );
    }
    callBackHandler();
    this.setState({});
  };

  loadURLParams(): boolean {
    const windowUrl = window.location.search;
    const params: URLSearchParams = new URLSearchParams(windowUrl);
    let projectId: any = params.get("project");
    if (projectId === null || projectId === "") {
      this.setState({ showProjectIdError: true });
      return false;
    }

    this.state.projectId = projectId;
    return true;
  }

  // This is function use get question data
  fetchAllQuestions(
    existingQuestionVsAnswerFilterMap: any,
    existingColumnId: any,
    finalCallBackFunction: any = null
  ) {
    fetch(this.GET_QUESTIONS_URL + "&project_id=" + this.state.projectId)
      .then((response) => {
        return response.json();
      })
      .then(
        (allQuestions) => {
          if (
            allQuestions != null &&
            allQuestions.constructor.name === "Object"
          ) {
            this.showMessage("error", allQuestions.error);
            return;
          }

          if (allQuestions == null || allQuestions.length === 0) {
            this.showMessage(
              "error",
              NO_QUESTION_DATA_FOUND() + this.state.projectId
            );
            return;
          }
          let columnId =
            existingColumnId != null ? existingColumnId : this.getNewColumnId();
          let questionVsAnswerMap = new Map();
          let questionVsAnswerFilterMap: Map<number, any> =
            existingQuestionVsAnswerFilterMap != null
              ? existingQuestionVsAnswerFilterMap
              : new Map();

          allQuestions.forEach((question: any, i: any) => {
            if (
              question.question_type === "single_punch" ||
              question.question_type === "multi_punch" ||
              question.question_type === "GEO_IP_LAT_LNG" ||
              question.question_type === "open_ended"
            ) {
              let answerSelectMap: Map<number, boolean> = new Map();

              if (existingQuestionVsAnswerFilterMap == null) {
                question.answers.forEach((element: any) => {
                  answerSelectMap.set(element.id, true);
                  this.state.allAnswerMap.set(element.id, element);
                });
              } else {
                question.answers.forEach((element: any) => {
                  let filterVal: boolean =
                    existingQuestionVsAnswerFilterMap
                      .get(question.id)
                      .get(element.id) != null
                      ? existingQuestionVsAnswerFilterMap
                          .get(question.id)
                          .get(element.id)
                      : true;
                  answerSelectMap.set(element.id, filterVal);
                  this.state.allAnswerMap.set(element.id, element);
                });
              }

              questionVsAnswerFilterMap.set(question.id, answerSelectMap);
              questionVsAnswerMap.set(
                question.id,
                this.buildQuestionVsAnswerEntry(
                  columnId,
                  question,
                  null,
                  null,
                  null,
                  0,
                  null,
                  null,
                  null
                )
              );
            }
          });

          //Update all required data in the state
          this.state.columnVsQuestionVsAnswerMap.set(
            columnId,
            questionVsAnswerMap
          );
          this.state.selectedColumnId = columnId;
          this.state.columnVsFilterMap.set(columnId, questionVsAnswerFilterMap);
          this.state.columnVsRestrictedUserIds.set(columnId, new Map());
          this.fetchQuestionAnswerByUsers(
            questionVsAnswerMap,
            finalCallBackFunction
          );
        },
        (error: any) => {
          this.setState({ showProjectIdError: true });
        }
      );
  }

  fetchQuestionAnswerByUsers(
    questionVsAnswerMap: Map<number, any>,
    finalCallBackFunction: any = null
  ) {
    fetch(this.GET_ANSWERS_URL + "&project_id=" + this.state.projectId)
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          this.showMessage("error", ANSWER_JSON_PARSE_ERROR());
          return null;
        }
      })
      .then((allUserAnswers) => {
        if (
          allUserAnswers != null &&
          allUserAnswers.constructor.name === "Object"
        ) {
          this.showMessage("error", allUserAnswers.error);
          return;
        }

        if (allUserAnswers == null || allUserAnswers.length === 0) {
          this.showMessage(
            "error",
            NO_ANSWER_DATA_FOUND() + this.state.projectId
          );
          return;
        }

        this.state.allUserAnswers = allUserAnswers;

        //Will be initialized only once
        if (this.state.answerIdVsUserIdsMap.size === 0) {
          this.state.allUserAnswers.forEach((userAnswer: any, i: any) => {
            if (!this.state.answerIdVsUserIdsMap.has(userAnswer.answer_id)) {
              let userIdMap = new Map();
              userIdMap.set(userAnswer.user_id, null);
              this.state.answerIdVsUserIdsMap.set(
                userAnswer.answer_id,
                userIdMap
              );
            } else {
              this.state.answerIdVsUserIdsMap
                .get(userAnswer.answer_id)
                .set(userAnswer.user_id, null);
            }
          });
        }
        this.processAllUserAnswersAndUpdateAnswerMap(
          questionVsAnswerMap,
          this.state.selectedColumnId
        );

        if (finalCallBackFunction != null) {
          finalCallBackFunction();
        }
        //To refresh the UI based on the latest data in state
        this.setState({});
      });
  }

  parseAnswerTextAndGetCoordinates(answerText: any) {
    try {
      return JSON.parse(answerText);
    } catch (Error) {
      console.log("JSON Parse Error");
      return null;
    }
  }

  processAllUserAnswersAndUpdateAnswerMap = (
    questionVsAnswerMap: Map<number, any>,
    columnId: number
  ) => {
    if (
      this.state.allUserAnswers == null ||
      this.state.allUserAnswers.length === 0
    ) {
      return;
    }

    this.state.allUserAnswers.forEach((userAnswer: any, i: any) => {
      let selectedFilter: any = this.state.columnVsFilterMap.get(columnId);

      let currentRestrictedMap =
        this.state.columnVsRestrictedUserIds.get(columnId);
      if (questionVsAnswerMap.has(userAnswer.question_id)) {
        let questionAnsObj: any = questionVsAnswerMap.get(
          userAnswer.question_id
        );

        let restrictedByUserId: boolean =
          currentRestrictedMap != null &&
          currentRestrictedMap.has(userAnswer.user_id);

        let isAnswerSelected: boolean = selectedFilter
          .get(questionAnsObj.question.id)
          .get(userAnswer.answer_id);

        if (
          questionAnsObj.question.question_type === "open_ended" &&
          !restrictedByUserId
        ) {
          if (questionAnsObj.openEndedAnswerArray == null) {
            questionAnsObj.openEndedAnswerArray = [];
          }
          questionAnsObj.openEndedAnswerArray.push(userAnswer);
        } else if (
          questionAnsObj.question.question_type === "GEO_IP_LAT_LNG" &&
          !restrictedByUserId
        ) {
          if (questionAnsObj.geoTypeAnswerArray == null) {
            questionAnsObj.geoTypeAnswerArray = [];
          }

          let coordinates: any = this.parseAnswerTextAndGetCoordinates(
            userAnswer.answer_json
          );

          if (coordinates != null) {
            questionAnsObj.geoTypeAnswerArray.push({
              answer: userAnswer,
              coordinates: coordinates,
            });
          }
        } else if (
          questionAnsObj.question.question_type === "single_punch" ||
          questionAnsObj.question.question_type === "multi_punch"
        ) {
          if (questionAnsObj.answerMap == null) {
            questionAnsObj.answerMap = new Map();
          }

          let countValue: number = 0;
          if (isAnswerSelected && !restrictedByUserId) {
            countValue = 1;
          }

          if (questionAnsObj.answerMap.get(userAnswer.answer_id) == null) {
            questionAnsObj.answerMap.set(userAnswer.answer_id, {
              answerId: userAnswer.answer_id,
              answer: userAnswer,
              selectedAnswerText: questionAnsObj.question.answers.filter(
                (answerItem: any) => answerItem.id === userAnswer.answer_id
              )[0].answer_text,
              numberOfPeopleAnswered: countValue,
              percentageOfPeopleAnswered: 0,
              includeInResult: isAnswerSelected,
            });
          } else {
            if (countValue === 1) {
              questionAnsObj.answerMap.get(userAnswer.answer_id)
                .numberOfPeopleAnswered++;
            }
          }
        }
      }
    });

    //Consolidation
    questionVsAnswerMap.forEach((questionObj: any, key: any) => {
      this.calculateConsolidatedDetails(questionObj, columnId);
    });

    this.processQuickView();
  };

  calculateConsolidatedDetails = (questionObj: any, columnId: number) => {
    let selectedFilter: any = this.state.columnVsFilterMap.get(columnId);
    questionObj.consolidatedData.answerTextArray = [];
    questionObj.consolidatedData.answerIdArray = [];
    questionObj.consolidatedData.numberOfPeopleAnsweredArray = [];
    questionObj.consolidatedData.totalNumberOfPeopleAnswered = 0;

    if (questionObj.answerMap != null) {
      questionObj.answerMap.forEach((answerObj: any) => {
        questionObj.consolidatedData.answerTextArray.push(
          this.state.allAnswerMap.get(answerObj.answer.answer_id).answer_text
        );
        questionObj.consolidatedData.answerIdArray.push(
          answerObj.answer.answer_id
        );
        questionObj.consolidatedData.totalNumberOfPeopleAnswered +=
          answerObj.numberOfPeopleAnswered;
      });
    }

    questionObj.consolidatedData.numberOfPeopleAnsweredArray = [];
    questionObj.consolidatedData.answerIdArray.forEach((answerId: number) => {
      let colorCodeIndex = (answerId % CHART_COLOR_CODE.length) - 1;
      if (colorCodeIndex < 0) {
        colorCodeIndex = 0;
      }
      let answerObj = questionObj.answerMap.get(answerId);
      if (
        answerObj != null &&
        selectedFilter
          .get(questionObj.question.id)
          .get(answerObj.answer.answer_id) === true
      ) {
        questionObj.consolidatedData.numberOfPeopleAnsweredArray.push({
          noOfPeople: answerObj.numberOfPeopleAnswered,
          label: this.state.allAnswerMap.get(answerObj.answer.answer_id)
            .answer_text,
          color: CHART_COLOR_CODE[colorCodeIndex],
          columnSettings: { fill: CHART_COLOR_CODE[colorCodeIndex] },
        });
        answerObj.percentageOfPeopleAnswered = this.calcPercentage(
          answerObj.numberOfPeopleAnswered,
          questionObj.consolidatedData.totalNumberOfPeopleAnswered
        );
      } else {
        if (this.state.settings.diagram.displayFilteredValue) {
          questionObj.consolidatedData.numberOfPeopleAnsweredArray.push({
            noOfPeople: answerObj.numberOfPeopleAnswered,
            label: this.state.allAnswerMap.get(answerObj.answer.answer_id)
              .answer_text,
            color: CHART_COLOR_CODE[colorCodeIndex],
            columnSettings: { fill: CHART_COLOR_CODE[colorCodeIndex] },
          });
        }
      }
    });
  };

  calcPercentage = (numerator: number, denominator: number): string => {
    if (numerator === 0 || denominator === 0) {
      return "0";
    }

    let num: number = (numerator / denominator) * 100;
    return num.toFixed(1);
  };

  handleSwitchChange = (event: any) => {
    this.state.switchStatus = !this.state.switchStatus;
    this.setState({});
  };

  handleSettingsToggle = () => {
    this.state.showSettingModal = !this.state.showSettingModal;
    this.setState({});
  };

  columnChangeHandler = (columnId: any) => {
    //Note: This will be triggered during delete column also
    if (
      this.state.selectedColumnId != columnId &&
      this.state.columnVsQuestionVsAnswerMap.has(columnId)
    ) {
      this.state.selectedColumnId = columnId;
      this.setState({});
    }
  };

  closeButtonClickHandler = (columnId: any) => {
    if (this.state.columnVsFilterMap.size < 2) {
      this.showMessage("error", MIN_COULUMN_ERROR());
      return;
    }
    this.state.quickViewMap.delete(columnId);
    this.state.columnVsFilterMap.delete(columnId);
    this.state.columnVsQuestionVsAnswerMap.delete(columnId);
    this.state.columnVsRestrictedUserIds.delete(columnId);
    this.state.selectedColumnId = this.state.columnVsQuestionVsAnswerMap
      .keys()
      .next().value;
    this.setState({});
  };

  getNewColumnId = (): number => {
    return this.getTimeStampNumber();
  };

  getTimeStampNumber = (): number => {
    return new Date().getTime();
  };

  buildConsolidatedData = (
    totalNumberOfPeopleAnswered: any,
    answerTextArray: any,
    answerIdArray: any,
    numberOfPeopleAnsweredArray: any
  ): any => {
    return {
      totalNumberOfPeopleAnswered: totalNumberOfPeopleAnswered,
      answerTextArray: answerTextArray,
      answerIdArray: answerIdArray,
      numberOfPeopleAnsweredArray: numberOfPeopleAnsweredArray,
    };
  };

  buildQuestionVsAnswerEntry = (
    columnId: any,
    question: any,
    answerMap: any,
    openEndedAnswerArray: any,
    geoTypeAnswerArray: any,
    totalNumberOfPeopleAnswered: any,
    answerTextArray: any,
    answerIdArray: any,
    numberOfPeopleAnsweredArray: any
  ): any => {
    return {
      columnId: columnId,
      question: question,
      answerMap: answerMap,
      openEndedAnswerArray: openEndedAnswerArray,
      geoTypeAnswerArray: geoTypeAnswerArray,
      consolidatedData: this.buildConsolidatedData(
        totalNumberOfPeopleAnswered,
        answerTextArray,
        answerIdArray,
        numberOfPeopleAnsweredArray
      ),
    };
  };

  handleRowComponentAddColumnButtonClick = (currentColumnIndex: number) => {
    this.handleAddColumnClick(false);
    this.moveColumnToDestinationIndex(
      this.state.selectedColumnId,
      currentColumnIndex + 1
    );
  };

  handleAddColumnClick = (isDuplicateOfSelectedColumn: boolean) => {
    let newColumnId = this.getNewColumnId();

    if (
      this.state.columnVsFilterMap == null ||
      this.state.columnVsFilterMap.size === 0
    ) {
      //This case is not possible, as we already have validation on column removal
      console.log("Data Error");
      return;
    }

    let questionVsAnswerFilterMap: Map<any, any> = new Map();
    let questionVsAnswerMap = new Map();
    let restrictedUserIdsMap = new Map();

    this.state.columnVsFilterMap.set(newColumnId, questionVsAnswerFilterMap);
    this.state.columnVsRestrictedUserIds.set(newColumnId, restrictedUserIdsMap);
    this.state.columnVsQuestionVsAnswerMap.set(
      newColumnId,
      questionVsAnswerMap
    );

    if (isDuplicateOfSelectedColumn) {
      //Copying the filter data from the selected filter Map
      this.state.columnVsFilterMap
        .get(this.state.selectedColumnId)
        .forEach((answersMap: any, key: any) => {
          let copyAnswersMap: Map<number, boolean> = new Map();
          answersMap.forEach((value: any, key: any) => {
            copyAnswersMap.set(key, value);
          });
          questionVsAnswerFilterMap.set(key, copyAnswersMap);
        });

      //Copying the question from selected Map
      this.state.columnVsQuestionVsAnswerMap
        .get(this.state.selectedColumnId)
        .forEach((questionObj: any, key: any) => {
          let newQuestionVsAnswerEntry = this.buildQuestionVsAnswerEntry(
            newColumnId,
            questionObj.question,
            null,
            null,
            null,
            0,
            null,
            null,
            null
          );
          questionVsAnswerMap.set(key, newQuestionVsAnswerEntry);

          //Copying restricted user ids from selected restrictedUserIdsMap
          this.state.columnVsRestrictedUserIds
            .get(this.state.selectedColumnId)
            .forEach((value: any, key: any) => {
              restrictedUserIdsMap.set(key, value);
            });
        });
    } else {
      //Copying the filter data from first element of the map 'state.columnVsFilterMap'
      let firstFilterMap: any = this.state.columnVsFilterMap
        .values()
        .next().value;
      firstFilterMap.forEach((answersMap: any, key: any) => {
        let copyAnswersMap: Map<number, boolean> = new Map();
        answersMap.forEach((value: any, key: any) => {
          copyAnswersMap.set(key, true);
        });
        questionVsAnswerFilterMap.set(key, copyAnswersMap);
      });

      //Copying the question from first element of the map 'state.columnVsQuestionVsAnswerMap'
      let firstQuestionVsAnswerMap: any = this.state.columnVsQuestionVsAnswerMap
        .values()
        .next().value;
      firstQuestionVsAnswerMap.forEach((questionObj: any, key: any) => {
        let newQuestionVsAnswerEntry = this.buildQuestionVsAnswerEntry(
          newColumnId,
          questionObj.question,
          null,
          null,
          null,
          0,
          null,
          null,
          null
        );
        questionVsAnswerMap.set(key, newQuestionVsAnswerEntry);
      });
    }
    this.state.selectedColumnId = newColumnId;
    this.processAllUserAnswersAndUpdateAnswerMap(
      questionVsAnswerMap,
      newColumnId
    );
    this.reCalculateBasedOnSettingsChange();
    this.setState({});
    toast.success(DASBOARD_NEW_COMPARISION_CREATED_SUCCESS());
  };

  handleAnswerFilterSelectChange = (
    questionId: any,
    answerId: any,
    columnId: any
  ) => {
    this.state.selectedColumnId = columnId;
    let selectedFilter: any = this.state.columnVsFilterMap.get(
      this.state.selectedColumnId
    );

    selectedFilter
      .get(questionId)
      .set(answerId, !selectedFilter.get(questionId).get(answerId));

    let questionVsAnswerMap = this.state.columnVsQuestionVsAnswerMap.get(
      this.state.selectedColumnId
    );

    //Existing processed data clean-up
    questionVsAnswerMap.forEach((questionObj: any, key: any) => {
      questionObj.answerMap = new Map();
      questionObj.consolidatedData = {};
      questionObj.openEndedAnswerArray = [];
      questionObj.geoTypeAnswerArray = [];
    });
    this.updateRestrictedUserIdsByAnswerId();
    this.processAllUserAnswersAndUpdateAnswerMap(questionVsAnswerMap, columnId);
    this.refreshSideNavBarList();
    this.refreshAnswerDetailModalData(questionId, answerId, columnId);
    this.setState({});
  };

  refreshAnswerDetailModalData = (
    questionId: any,
    answerId: any,
    columnId: any
  ) => {
    if (this.state.answerDetailModal.show) {
      let questionVsAnswerObj: any =
        this.state.columnVsQuestionVsAnswerMap.get(columnId);
      this.state.answerDetailModal.selectedAnswerIds =
        this.getSelectedAnswerIdsByQuestionId(columnId, questionId);

      let tempAnswerTableData: any =
        this.state.answerDetailModal.invocationMode === INVOCATION_MODE_QUESTION
          ? Array.from(
              questionVsAnswerObj.get(questionId).answerMap.values()
            ).sort((val1: any, val2: any) => {
              return val1.answerId - val2.answerId;
            })
          : [questionVsAnswerObj.get(questionId).answerMap.get(answerId)];

      this.state.answerDetailModal.answerTableData = tempAnswerTableData;
    }
  };

  refreshSideNavBarList = () => {
    let sideNavBar: SideNavBarList = this.sideNavBarListChildRef.current;
    sideNavBar.refreshUI();
  };

  refreshLoadSurveyDialogList = () => {
    let LoadSurveyDialog: LoadSurveyDialog =
      this.loadSurveyDialogChildRef.current;
    LoadSurveyDialog.loadData();
  };

  updateRestrictedUserIdsByAnswerId() {
    let updatedUserIdsFilter: any = new Map();
    this.state.columnVsFilterMap
      .get(this.state.selectedColumnId)
      .forEach((questionMap: any) => {
        for (let [answerId, isSelected] of questionMap.entries()) {
          if (!isSelected) {
            for (let [userId, value] of this.state.answerIdVsUserIdsMap
              .get(answerId)
              .entries()) {
              updatedUserIdsFilter.set(userId, value);
            }
          }
        }
      });
    this.state.columnVsRestrictedUserIds.set(
      this.state.selectedColumnId,
      updatedUserIdsFilter
    );
  }

  onColumnDragHandler = (columnId: any, destination: any) => {
    this.moveColumnToDestinationIndex(columnId, destination.index);
  };

  moveColumnToDestinationIndex = (
    columnId: number,
    destinationIndex: number
  ) => {
    let dragItem = this.state.columnVsQuestionVsAnswerMap.get(columnId);
    let dragItemFilter = this.state.columnVsFilterMap.get(columnId);
    let dragQuickViewItem = this.state.quickViewMap.get(columnId);

    this.state.columnVsQuestionVsAnswerMap.delete(columnId);
    this.state.columnVsFilterMap.delete(columnId);
    this.state.quickViewMap.delete(columnId);

    let newColumnVsQuestionVsAnswerMap = new Map();
    let newColumnVsFilterMap = new Map();
    let newQuickViewMap = new Map();

    let columnIdKeysArray = Array.from(
      this.state.columnVsQuestionVsAnswerMap.keys()
    );

    for (let i = 0; i < destinationIndex; i++) {
      var key = columnIdKeysArray[i];
      var val1 = this.state.columnVsQuestionVsAnswerMap.get(key);
      var val2 = this.state.columnVsFilterMap.get(key);
      var val3 = this.state.quickViewMap.get(key);
      newColumnVsQuestionVsAnswerMap.set(key, val1);
      newColumnVsFilterMap.set(key, val2);
      newQuickViewMap.set(key, val3);
    }

    newColumnVsQuestionVsAnswerMap.set(columnId, dragItem);
    newColumnVsFilterMap.set(columnId, dragItemFilter);
    newQuickViewMap.set(columnId, dragQuickViewItem);

    for (let i = destinationIndex; i < columnIdKeysArray.length; i++) {
      var key = columnIdKeysArray[i];
      var val1 = this.state.columnVsQuestionVsAnswerMap.get(key);
      var val2 = this.state.columnVsFilterMap.get(key);
      var val3 = this.state.quickViewMap.get(key);
      newColumnVsQuestionVsAnswerMap.set(key, val1);
      newColumnVsFilterMap.set(key, val2);
      newQuickViewMap.set(key, val3);
    }

    this.state.columnVsQuestionVsAnswerMap = newColumnVsQuestionVsAnswerMap;
    this.state.columnVsFilterMap = newColumnVsFilterMap;
    this.state.quickViewMap = newQuickViewMap;
    this.setState({});
  };

  changeSelectedFilterAccordionQuestionId = (questionId: any) => {
    this.state.isSideNavBarOpen = true;
    this.state.selectedFilterAccordionQuestionId = questionId;
    this.setState({});
  };

  toggleQuestionAccordionHandler = (questionId: any, expanded: boolean) => {
    if (expanded) {
      this.state.selectedFilterAccordionQuestionId = questionId;
    } else {
      this.state.selectedFilterAccordionQuestionId = -1;
    }
    this.setState({});
  };

  handleCloseSideNavBarList = () => {
    this.state.isSideNavBarOpen = false;
    this.setState({});
  };

  handleOpenSideNavBarList = () => {
    this.state.isSideNavBarOpen = true;
    this.setState({});
  };

  handleResetAnswerSelection = (
    questionId: number,
    columnId: number,
    newState: boolean
  ) => {
    this.state.selectedColumnId = columnId;
    let selectedFilter: any = this.state.columnVsFilterMap.get(
      this.state.selectedColumnId
    );

    let answerMap: Map<number, boolean> = selectedFilter.get(questionId);
    answerMap.forEach((value: any, key: any) => {
      answerMap.set(key, newState);
    });

    let questionVsAnswerMap = this.state.columnVsQuestionVsAnswerMap.get(
      this.state.selectedColumnId
    );

    //Existing processed data clean-up
    questionVsAnswerMap.forEach((questionObj: any, key: any) => {
      questionObj.answerMap = new Map();
      questionObj.consolidatedData = {};
      questionObj.openEndedAnswerArray = [];
      questionObj.geoTypeAnswerArray = [];
    });

    this.updateRestrictedUserIdsByAnswerId();
    this.processAllUserAnswersAndUpdateAnswerMap(questionVsAnswerMap, columnId);
    this.setState({});
  };

  onUpdateSettings = (updatedObject: any) => {
    this.state.settings.answerTable.rowsPerPage = updatedObject.rowsPerPage;
    this.state.settings.diagram.displayFilteredValue =
      updatedObject.displayFilteredValueInDiagram;
    this.state.settings.answerTable.updateRequired = true;
    this.state.settings.answerTable.displaySamePageAllColumns =
      updatedObject.displaySamePageAllColumns;
    this.handleSettingsToggle();
    this.reCalculateBasedOnSettingsChange();
  };

  reCalculateBasedOnSettingsChange = () => {
    this.state.columnVsFilterMap.forEach((obj: any, columnId: any) => {
      this.state.columnVsQuestionVsAnswerMap
        .get(columnId)
        .forEach((questionObj: any, key: any) => {
          this.calculateConsolidatedDetails(questionObj, columnId);
        });
    });
  };

  handleAnswerDetailLinkClick = (
    selectedColumnId: number,
    questionId: number,
    answerId: number
  ) => {
    let questionVsAnswerEntry: any = this.state.columnVsQuestionVsAnswerMap
      .get(selectedColumnId)
      .get(questionId);

    let invocationMode: string =
      answerId !== null && answerId !== undefined
        ? INVOCATION_MODE_ANSWER
        : INVOCATION_MODE_QUESTION;

    this.setState({
      answerDetailModal: {
        selectedQuestionId: questionId,
        selectedAnswerIds:
          invocationMode === INVOCATION_MODE_ANSWER
            ? [answerId]
            : this.getSelectedAnswerIdsByQuestionId(
                selectedColumnId,
                questionId
              ),
        show: true,
        answerTableData:
          invocationMode === INVOCATION_MODE_QUESTION
            ? Array.from(questionVsAnswerEntry.answerMap.values()).sort(
                (val1: any, val2: any) => {
                  return val1.answerId - val2.answerId;
                }
              )
            : [questionVsAnswerEntry.answerMap.get(answerId)],
        questionVsAnswerEntry: questionVsAnswerEntry,
        invocationMode: invocationMode,
      },
    });
  };

  handleAnalysisNameChange = (event: any) => {
    this.state.saveAnalysisModal.analysisName = event.target.value;
  };

  setLoadAnalysisButtonStatus = (status: boolean) => {
    this.setState({ isLoadAnalysisButtonEnabled: status });
  };

  processQuickView = () => {
    this.state.quickViewMap = new Map();

    let questionIdVsShowObj: any = new Map();
    let columnIds: any = [];
    this.state.columnVsFilterMap.forEach((filterMap: any, columnId: any) => {
      let quickSurveyItemsMap: any = new Map();
      let allQuestions = this.state.columnVsQuestionVsAnswerMap.get(columnId);
      filterMap.forEach((filterItem: any, questionId: any) => {
        if (!questionIdVsShowObj.has(questionId)) {
          let showObj = {
            show: false,
          };
          questionIdVsShowObj.set(questionId, showObj);
        }
        let showObj = questionIdVsShowObj.get(questionId);

        let questionText = allQuestions.get(questionId).question.question_text;
        let questionType = allQuestions.get(questionId).question.question_type;
        let totalAnswerSize = filterItem.size;
        let totalTrueItems = 0;
        let selectedItems: any = [];

        let questionTypeClassName = "badge-warning";

        if (questionType === "single_punch") {
          questionTypeClassName = "badge-primary";
        } else if (questionType === "multi_punch") {
          questionTypeClassName = "badge-success";
        }

        filterItem.forEach((answer: any, answerId: any) => {
          if (answer === true) {
            selectedItems.push(
              <span className={`badge ${questionTypeClassName} mr-2`}>
                {this.state.allAnswerMap.get(answerId).answer_text}
              </span>
            );
            totalTrueItems++;
          }
        });

        showObj.show = showObj.show || totalAnswerSize !== totalTrueItems;

        if (totalAnswerSize !== totalTrueItems) {
          quickSurveyItemsMap.set(questionId, {
            questionId: questionId,
            questionText: questionText,
            selectedAnswersText: selectedItems,
          });
        } else {
          quickSurveyItemsMap.set(questionId, {
            questionId: questionId,
            questionText: questionText,
            selectedAnswersText: ["All"],
          });
        }
      });
      this.state.quickViewMap.set(columnId, quickSurveyItemsMap);
      columnIds.push(columnId);
    });

    //if (columnIds.length > 1) {
    questionIdVsShowObj.forEach((showObj: any, questionId: any) => {
      if (!showObj.show) {
        for (let i = 0; i < columnIds.length; i++) {
          this.state.quickViewMap.get(columnIds[i]).delete(questionId);
        }
      }
    });
    //}

    this.setState({});
  };

  private getSelectedAnswerIdsByQuestionId = (
    selectedColumnId: number,
    questionId: number
  ): any => {
    let selectedAnswerIds: any = [];
    this.state.columnVsFilterMap
      .get(selectedColumnId)
      .get(questionId)
      .forEach((isSelected: any, answerId: any) => {
        if (isSelected) {
          selectedAnswerIds.push(answerId);
        }
      });
    return selectedAnswerIds;
  };

  toggleQuickViewCollapse = () => {
    this.state.quickViewOpen = !this.state.quickViewOpen;
    this.setState({});
  };

  scrollToQuestion = (questionId: any) => {
    const element = document.getElementById("questionRowDiv" + questionId);
    if (element !== null) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  upDownButtonClickHandler = (incrementFlag: boolean) => {
    let questionIds = Array.from(
      this.state.columnVsQuestionVsAnswerMap
        .get(this.state.selectedColumnId)
        .keys()
    );
    if (
      incrementFlag &&
      this.state.currentQuestionScrollPos < questionIds.length - 1
    ) {
      this.state.currentQuestionScrollPos++;
    } else if (!incrementFlag && this.state.currentQuestionScrollPos > 0) {
      this.state.currentQuestionScrollPos--;
    }

    this.scrollToQuestion(
      questionIds[this.state.currentQuestionScrollPos]
    );
  };

  showMessage = (messageType: any, message: any) => {
    if (messageType === "success") {
      toast.success(message);
    } else if (messageType === "info") {
      toast.info(message);
    } else {
      toast.error(message);
    }
  };

  onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    this.onColumnDragHandler(parseInt(draggableId), destination);
  };

  handleToggleQuestionListModal = () => {
    this.state.showQuestionListModal = !this.state.showQuestionListModal;
    this.setState({});
  };

  render() {
    return (
      <>
        {this.state.showProjectIdError ? (
          <FullPageError error={INVALID_PROJECT_ID_IN_QUERY_PARAM()} />
        ) : this.state.columnVsQuestionVsAnswerMap &&
          this.state.columnVsQuestionVsAnswerMap.size > 0 &&
          this.state.selectedColumnId !== -1 ? (
          <>
            <ReactTooltip
              type="dark"
              effect="solid"
              html={true}
              className="tooltip-word-break"
            />
            <div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />

              <div
                className={`sweet-loading ${
                  this.state.loading ? "loading-style" : ""
                }`}
              >
                <ClipLoader
                  color="#188874"
                  loading={this.state.loading}
                  size={150}
                />
              </div>
            </div>

            <Toolbar
              handleOpenSideNavBarList={this.handleOpenSideNavBarList}
              handleAddColumnClick={this.handleAddColumnClick}
              isLoadAnalysisButtonEnabled={
                this.state.isLoadAnalysisButtonEnabled
              }
              toggleSaveAnalysis={this.toggleSaveAnalysis}
              toggleLoadAnalysis={this.toggleLoadAnalysis}
              handleSaveAnalysisButtonClick={this.handleSaveAnalysisButtonClick}
              handleLoadAnalysisButtonClick={this.handleLoadAnalysisButtonClick}
              switchStatus={this.state.switchStatus}
              handleSwitchChange={this.handleSwitchChange}
              handleSettingsToggle={this.handleSettingsToggle}
              handleToggleQuestionListModal={this.handleToggleQuestionListModal}
              upDownButtonClickHandler={this.upDownButtonClickHandler}
            />
            <div className="content-main  ">
              {/* <!-- [ Header ] end --> */}
              {/* this is component used to side nav bar */}
              <SideNavBarList
                ref={this.sideNavBarListChildRef}
                innerRef={this.sideNavBarListDivRef}
                stateValue={this.state}
                handleCloseSideNavBarList={this.handleCloseSideNavBarList}
                handleResetAnswerSelection={this.handleResetAnswerSelection}
                handleAnswerFilterSelectChange={
                  this.handleAnswerFilterSelectChange
                }
                toggleQuestionAccordionHandler={
                  this.toggleQuestionAccordionHandler
                }
              />
              {/* <!-- [ Main Content ] start --> */}
              <div className="pcoded-main-container survey-dashboard">
                <ScrollSync enabled={this.state.switchStatus}>
                  <div className="pcoded-content">
                    {/* <!-- [ breadcrumb ] end --> */}
                    <div>
                      <div className={`row boxbg`}>
                        <div className="col-12 mb-3 question-glow-effect border-dark-blue mt-10">
                          <div className="card bg-dark-blue border-radius-0">
                            <div
                              className="card-header border-0 min-height-40"
                              style={{ marginTop: "-5px" }}
                            >
                              <div className="question-text card-title align-items-start flex-column">
                                <Box
                                  className="card-label fw-bolder fs-3 mb-1 text-white"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {DASBOARD_FILTERS_TITLE()}
                                </Box>
                              </div>
                              <div className="card-title align-items-start flex-column">
                                <ul className="nav">
                                  <li className="nav-item">
                                    <span
                                      className="pointer p-0"
                                      data-tip={FILTER_BUTTON()}
                                      onClick={(e) => {
                                        this.handleOpenSideNavBarList();
                                      }}
                                    >
                                      <i
                                        className="fa-solid fa-filter text-white"
                                        style={{ fontSize: "15px" }}
                                      ></i>
                                    </span>
                                  </li>
                                  <li className="nav-item">
                                    <span
                                      className="pointer p-0 ml-15"
                                      data-tip={COLLAPSE_BUTTON()}
                                      onClick={(e) => {
                                        this.toggleQuickViewCollapse();
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={`${
                                          this.state.quickViewOpen === false
                                            ? "rotate-180"
                                            : ""
                                        }`}
                                        version="1.1"
                                        id="Capa_1"
                                        x="0px"
                                        y="0px"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 292.362 292.362"
                                        style={{
                                          marginTop: "-1px",
                                          fill: "#ffff",
                                        }}
                                      >
                                        <g xmlns="http://www.w3.org/2000/svg">
                                          <path d="M286.935,197.286L159.028,69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233,1.807-12.85,5.424L5.424,197.286   C1.807,200.9,0,205.184,0,210.132s1.807,9.233,5.424,12.847c3.621,3.617,7.902,5.428,12.85,5.428h255.813   c4.949,0,9.233-1.811,12.848-5.428c3.613-3.613,5.427-7.898,5.427-12.847S290.548,200.9,286.935,197.286z" />
                                        </g>
                                      </svg>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <Collapse in={this.state.quickViewOpen}>
                            <div className=" mb-2">
                              <ScrollSyncPane group="horizontal">
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                  <Droppable
                                    droppableId={"quickViewDrop"}
                                    direction="horizontal"
                                  >
                                    {(proivded) => (
                                      <div
                                        className={this.state.scrollPage}
                                        ref={proivded.innerRef}
                                      >
                                        <div className="d-flex">
                                          {Array.from(
                                            this.state.quickViewMap.keys()
                                          ).map((key: any, index: any) => {
                                            return (
                                              <Draggable
                                                draggableId={"" + key}
                                                index={index}
                                                key={key}
                                              >
                                                {(provided) => (
                                                  <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    onClick={(e) => {
                                                      this.columnChangeHandler(
                                                        key
                                                      );
                                                    }}
                                                    className={` ${
                                                      this.state
                                                        .columnVsQuestionVsAnswerMap
                                                        .size == 1
                                                        ? "col-xl-12 col-md-12 full-width"
                                                        : "col-12 col-xl-6 col-md-6"
                                                    }  col-sm-12 col-xs-12 p-7`}
                                                  >
                                                    <div
                                                      className={`card border-radius-0  ${
                                                        this.state
                                                          .selectedColumnId ==
                                                        key
                                                          ? "border-primary"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div
                                                        className={`card-body p-0`}
                                                      >
                                                        <SurveyQuickViewTableComponent
                                                          onSurveyInfoRowComponentClick={
                                                            this
                                                              .changeSelectedFilterAccordionQuestionId
                                                          }
                                                          scrollToQuestion={
                                                            this
                                                              .scrollToQuestion
                                                          }
                                                          quickViewData={Array.from(
                                                            this.state.quickViewMap
                                                              .get(key)
                                                              .values()
                                                          )}
                                                        />
                                                        <div className="p-2"></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            );
                                          })}
                                          {proivded.placeholder}
                                        </div>
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </ScrollSyncPane>
                            </div>
                          </Collapse>
                        </div>

                        {this.state.columnVsQuestionVsAnswerMap &&
                        this.state.columnVsQuestionVsAnswerMap.size > 0
                          ? Array.from(
                              this.state.columnVsQuestionVsAnswerMap
                                .values()
                                .next()
                                .value.values()
                            ).map((questionVsAnswerEntry: any, index: any) => {
                              return (
                                <SurveyInfoRowComponent
                                  handleAddColumnClick={
                                    this.handleAddColumnClick
                                  }
                                  handleRowComponentAddColumnButtonClick={
                                    this.handleRowComponentAddColumnButtonClick
                                  }
                                  onDragEnd={this.onDragEnd}
                                  handleAnswerDetailLinkClick={
                                    this.handleAnswerDetailLinkClick
                                  }
                                  settings={this.state.settings}
                                  answerTableSettings={
                                    this.state.settings.answerTable
                                  }
                                  onColumnDragHandler={this.onColumnDragHandler}
                                  onSurveyInfoRowComponentClick={
                                    this.changeSelectedFilterAccordionQuestionId
                                  }
                                  key={index}
                                  selectedColumnId={this.state.selectedColumnId}
                                  selectedFilter={this.state.columnVsFilterMap.get(
                                    this.state.selectedColumnId
                                  )}
                                  question={questionVsAnswerEntry.question}
                                  columnVsQuestionVsAnswerMap={
                                    this.state.columnVsQuestionVsAnswerMap
                                  }
                                  columnChangeHandler={this.columnChangeHandler}
                                  closeButtonClickHandler={
                                    this.closeButtonClickHandler
                                  }
                                  index={index}
                                  scrollPage={`${this.state.scrollPage}`}
                                  handleSelectChange={
                                    this.handleAnswerFilterSelectChange
                                  }
                                />
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </ScrollSync>
              </div>
              {/* for reference https://react-bootstrap.github.io/components/modal/ */}
              <AnswerDetailsDialog
                key={this.state.projectId}
                projectId={this.state.projectId}
                selectedQuestionId={
                  this.state.answerDetailModal.selectedQuestionId
                }
                selectedAnswerIds={
                  this.state.answerDetailModal.selectedAnswerIds
                }
                questionVsAnswerMap={this.state.columnVsQuestionVsAnswerMap.get(
                  this.state.selectedColumnId
                )}
                show={this.state.answerDetailModal.show}
                invocationMode={this.state.answerDetailModal.invocationMode}
                onHide={() => {
                  this.setState({ answerDetailModal: { show: false } });
                }}
                answerTableSettings={this.state.settings.answerTable}
                tableData={this.state.answerDetailModal.answerTableData}
                questionVsAnswerEntry={
                  this.state.answerDetailModal.questionVsAnswerEntry
                }
                allUserAnswers={this.state.allUserAnswers}
                handleSelectChange={this.handleAnswerFilterSelectChange}
              />
              <SaveSurveyDialog
                show={this.state.saveAnalysisModal.show}
                onHide={this.toggleSaveAnalysis}
                handleSaveAnalysisButtonClick={
                  this.handleSaveAnalysisButtonClick
                }
                analysisName={this.state.saveAnalysisModal.analysisName}
                handleAnalysisNameChange={this.handleAnalysisNameChange}
                errorMessage={this.state.saveAnalysisModal.errorMessage}
              />
              <LoadSurveyDialog
                showMessage={this.showMessage}
                ref={this.loadSurveyDialogChildRef}
                projectId={this.state.projectId}
                show={this.state.loadAnalysisModal.show}
                savedFilters={this.state.loadAnalysisModal.savedFilters}
                loadSavedAnalysisDataFunction={this.loadSavedAnalysisData}
                onHide={this.toggleLoadAnalysis}
                showCreateNewButton={this.state.loadAnalysisModal.showCreateNew}
                errorMessage={this.state.saveAnalysisModal.errorMessage}
                handleLoadAnalysisButtonClick={
                  this.handleLoadAnalysisButtonClick
                }
                setLoadAnalysisButtonStatus={this.setLoadAnalysisButtonStatus}
              />
              <SettingsDialog
                showSettingModal={this.state.showSettingModal}
                handleSettingsToggle={this.handleSettingsToggle}
                onUpdateSettings={this.onUpdateSettings}
                handleSwitchChange={this.handleSwitchChange}
                switchStatus={this.state.switchStatus}
                settings={this.state.settings}
              />
              <QuestionsListDialog
                show={this.state.showQuestionListModal}
                onHide={this.handleToggleQuestionListModal}
                questions={this.state.columnVsQuestionVsAnswerMap.get(
                  this.state.selectedColumnId
                )}
                scrollToQuestion={this.scrollToQuestion}
              />
              {/* <!-- [ navigation menu ] end --> */}
            </div>
          </>
        ) : (
          <SpinnerLoader />
        )}
      </>
    );
  }
}
