import React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Highlighter from "react-highlight-words";
import Checkbox from "@mui/material/Checkbox";

export class AnswerSelectionComponent extends React.Component<any> {
  handleChange = (event?: any) => {
    this.props.handleChange(this.props.answersItem.id);
  };

  render() {
    return (
      <AccordionDetails className="pb-0 pt-0 m-0">
        <Checkbox
          checked={this.props.isSelected}
          onChange={(e) => {
            this.handleChange(e);
          }}
          name={`checkAnswer${this.props.index}`}
          value={this.props.answersItem.id}
        />
        <Highlighter
          searchWords={[this.props.searchText]}
          autoEscape={true}
          textToHighlight={this.props.answersItem.answer_text}
        />
      </AccordionDetails>
    );
  }
}
