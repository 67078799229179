import React from "react";
import DataTable from "react-data-table-component";
import Draggable from "react-draggable";
import { QUESTION_TYPE_LABEL,USER_SURVEY_DETAILS_DIALOG_TITLE } from "../../common/DisplayMsgAndLabelConst";

export class UserSurveyDetailsDialog extends React.Component<any> {
  eventLogger = (e: MouseEvent, data: Object) => {
    console.log("Event: ", e);
    console.log("Data: ", data);
  };

  state = {
    rowsPerPage: this.props.answerTableSettings.rowsPerPage,
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  };

  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  getQuestionTypeIcon = (questionType: any) => {
    if (questionType === "single_punch") {
      return (
        <i
          className="fa-solid fa-table-columns text-primary"
          data-tip={QUESTION_TYPE_LABEL[questionType]}
        ></i>
      );
    } else if (questionType === "multi_punch") {
      return (
        <i
          className="fa-solid fa-database text-primary"
          data-tip={QUESTION_TYPE_LABEL[questionType]}
        ></i>
      );
    } else if (questionType === "GEO_IP_LAT_LNG") {
      return (
        <i
          className="fa-solid fa-earth-americas text-primary"
          data-tip={QUESTION_TYPE_LABEL[questionType]}
        ></i>
      );
    } else {
      return (
        <i
          className="fa-solid fa-file-lines text-primary"
          data-tip={QUESTION_TYPE_LABEL[questionType]}
        ></i>
      );
    }
  };

  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    return (
      <Draggable handle="strong" {...dragHandlers}>
        <div className="card flexible-modal">
          <strong>
            <div className="card-header pointer">
              <div className="card-title d-flex mb-0">
                <span className={`card-label fw-bolder fs-7 mb-0`}>
                  {USER_SURVEY_DETAILS_DIALOG_TITLE()} - {this.props.userId}
                </span>
              </div>
              <div className="card-toolbar">
                <span
                  className={`ms-auto pointer p-0`}
                  onClick={this.props.handleUserSurveyDetailsDialogClose}
                >
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
            </div>
          </strong>
          <div className="card-body overflow-auto">
            <DataTable
              pagination={
                this.props.tableData != undefined &&
                this.props.tableData.length > 1
              }
              paginationPerPage={100}
              paginationRowsPerPageOptions={
                this.props.answerTableSettings.rowsPerPageOptions
              }
              columns={[
                {
                  sortable: false,
                  name: "#",
                  width: "50px",
                  cell: (rowData: any) => (
                    <div className="pt-1 pb-1">{rowData.sNo}</div>
                  ),
                },
                {
                  sortable: false,
                  name: "Type",
                  width: "75px",
                  cell: (rowData: any) =>
                    this.getQuestionTypeIcon(rowData.questionType),
                },
                {
                  sortable: false,
                  name: "Questions",
                  width: "200px;",
                  cell: (rowData: any) => (
                    <div className="pt-1 pb-1">{rowData.questionText}</div>
                  ),
                },
                {
                  sortable: false,
                  name: "Answers",
                  width: "200px;",
                  cell: (rowData: any) => (
                    <div className="pt-1 pb-1">{rowData.answerText}</div>
                  ),
                },
              ]}
              data={this.props.tableData}
            />
          </div>
          {/* <button onClick={this.props.handleUserSurveyDetailsDialogClose}>
              Close
            </button> */}
        </div>
      </Draggable>
    );
  }
}
