import React, { Component } from 'react';

export default class SpinnerLoader extends Component {

    render() {
        
        return (
            <div className={"centered"}>
                <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
        
    }
}
