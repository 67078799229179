import React from "react";
import { SurveyAnalysisDahboard } from "./modules/dashboard/SurveyAnalysisDahboard";
import "./App.css";
import { DisplayMsgUtil } from "./modules/common/DisplayMsgUtil";

export class App extends React.Component<any> {
  state = {
    msgInitCompleted: false,
  };
  componentDidMount() {
    DisplayMsgUtil.initInstance(() => {
      this.setState({ msgInitCompleted: true });
    });
  }
  render() {
    return this.state.msgInitCompleted ? <SurveyAnalysisDahboard /> : null;
  }
}
export default App;
