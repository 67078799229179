import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  SAVE_SURVEY_DIALOG_TITLE,
  SAVE_SURVEY_DIALOG_ANALYSIS_NAME_LABEL , 
  SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR,
  SAVE_SURVEY_DIALOG_SAVE_LABEL,
  SAVE_SURVEY_DIALOG_CLOSE_LABEL
  
} from "../../common/DisplayMsgAndLabelConst";

export class SaveSurveyDialog extends React.Component<any> {
  state = {
    analysisName: '',
    errorMessage:''
  };

  handleAnalysisNameChange = (event: any) => {
    this.props.handleAnalysisNameChange(event);
    let analysisName = event.target.value;
    this.setState({analysisName:analysisName});
    if(analysisName === undefined ||
      analysisName === null ||
      analysisName === "" ||
      analysisName.length === 0 ||
      analysisName.length > 50 ){
        this.setState({ errorMessage: SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR() });
      }else{
        this.setState({ errorMessage: '' });
      }
  };

  onHide = () => {
    this.setState({analysisName:'',errorMessage:''});
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{SAVE_SURVEY_DIALOG_TITLE()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label col-form-label-sm">
              {SAVE_SURVEY_DIALOG_ANALYSIS_NAME_LABEL()}
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                value={this.state.analysisName}
                onChange={this.handleAnalysisNameChange}
              />
              <label className="text-danger">{this.state.errorMessage}</label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              this.props.onHide();
            }}
          >
            {SAVE_SURVEY_DIALOG_CLOSE_LABEL()}
          </Button>
          <Button
            variant="primary"
            disabled={
              this.state.analysisName === undefined ||
              this.state.analysisName === null ||
              this.state.analysisName === "" ||
              this.state.analysisName.length === 0 ||
              this.state.analysisName.length > 50
            }
            onClick={(e) => {
              this.props.handleSaveAnalysisButtonClick();
            }}
          >
            {SAVE_SURVEY_DIALOG_SAVE_LABEL()}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
