export const INVALID_PROJECT_ID_IN_QUERY_PARAM =
  "Invalid project Id in the query param, sample url format: <url>/?project=1";
export const QUESTION_DATA_PARSE_ERROR = "Question data JSON Parse Error";
export const NO_QUESTION_DATA_FOUND = "No question data found for the project:";
export const ANSWER_JSON_PARSE_ERROR = "Answer data JSON Parse Error";
export const NO_ANSWER_DATA_FOUND = "No answer data found for the project:";
export const MIN_COULUMN_ERROR =
  "Please add a column before proceeding to delete";
export const ADD_COLUMN_NEW_BUTTON = "Create Comparison";
export const ADD_COLUMN_DUPLICATE_BUTTON = "Copy selected Comparison";
export const GLOBAL_SCROLL_BUTTON = "Scroll all";
export const SETTINGS_BUTTON = "Settings";
export const FILTER_BUTTON = "Filter";
export const COLLAPSE_BUTTON = "Collapse";
export const FREE_TEXT_ANSWER_TABLE_SNO_COLUMN = "#";
export const FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN = "Answer";
export const SURVEY_GRAPH_BAR_CHART_LABEL = "Survey Analysis";
export const SURVEY_TABLE_SNO_COLUMN = "#";
export const SURVEY_TABLE_ANSWER_COLUMN = "Answer";
export const SURVEY_TABLE_PERCENTAGE_COLUMN = "%";
export const SURVEY_TABLE_NUMBER_COLUMN = "Number";

export const SAVE_ANALYSIS_BUTTON = "Save Analysis";

export const LOAD_ANALYSIS_BUTTON = "Load Analysis";

export const LOAD_ANALYSIS_SUCCESS_MSG =
  "Analysis data has been loaded successfully";

export const FILTER_DATA_SAVE_MSG = "Filter Data Saved successfully.!";

export const ATLEAST_ONE_ANSWER_MSG = "Select at least one answer";

export const SETTING_ANSWER_TABLE_SETTINGS_LABEL = "Answer Table Settings";
export const SETTING_ROWS_PER_PAGE_LABEL = "Rows per page";
export const SETTING_DIAGRAM_LABEL = "Diagrams";
export const SETTING_DISPLAY_FILTERED_VALUE_LABEL = "Display filtered values";
export const SETTING_DISPLAY_SAME_PAGE_ALL_COLUMNS =
  "Display same page at all Comparisons";

export const SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR = "Character Limit is 50";
export const SAVE_ANALYSIS_EMPTY_ERROR = "Analysis Name is empty";
export const LOAD_ANALYSIS_ERROR = "You have not selected any analysis";

export const LOAD_SURVEY_DIALOG_TITLE = "Load Analysis";

export const LOAD_SURVEY_DIALOG_DELETE_ALL_BUTTON = "Delete All";
export const LOAD_SURVEY_DIALOG_CREATE_NEW_BUTTON = "Create New";
export const LOAD_SURVEY_DIALOG_CANCEL_BUTTON = "Cancel";
export const LOAD_SURVEY_DIALOG_LOAD_BUTTON = "Load";
export const LOAD_SURVEY_DIALOG_CONFIRMATION_TITLE = "Are you sure?";
export const LOAD_SURVEY_DIALOG_CONFIRMATION_TEXT =
  "Do you want to delete all the analysis?";

export const QUESTIONS_LIST_DIALOG_TITLE = "Questions";

export const SAVE_SURVEY_DIALOG_TITLE = "Save current analysis";

export const SAVE_SURVEY_DIALOG_ANALYSIS_NAME_LABEL = "Analysis Name";
export const SAVE_SURVEY_DIALOG_SAVE_LABEL = "Analysis Name";
export const SAVE_SURVEY_DIALOG_CLOSE_LABEL = "Analysis Name";

export const SETTINGS_DIALOG_TITLE = "Settings";

export const USER_SURVEY_DETAILS_DIALOG_TITLE = "User Survey Details";

export const DASBOARD_FILTERS_TITLE = "Filters";
export const DASBOARD_SAVE_SUCCESS_BEGINNING_TEXT = "Analysis";
export const DASBOARD_SAVE_SUCCESS_END_TEXT = "saved successfully";
export const DASBOARD_LOAD_ANALYSIS_DATA_ERROR = "Data Error !!!";
export const DASBOARD_LOAD_SUCCESS_BEGINNING_TEXT = "Analysis";
export const DASBOARD_LOAD_SUCCESS_END_TEXT = "saved successfully";
export const DASBOARD_NEW_COMPARISION_CREATED_SUCCESS =
  "New Comparison created!";

export const SURVEY_QUICKVIEW_TABLE_NO_DATA_ERROR =
  "There are no records to display. Please select some filters.";

export const ANSWER_FILTER_BAR_SEARCH_PLACEHOLDER = "Search...";

export const TOOLBAR_SCROLL_UP_BUTTON_TEXT = "Scroll up";
export const TOOLBAR_SCROLL_DOWN_BUTTON_TEXT = "Scroll Down";
export const TOOLBAR_SCROLL_TO_QUESTION_BUTTON_TEXT = "Scroll to Question";
