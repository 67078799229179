import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export class SurveyDonutChartComponent extends React.Component<any>{

    private series:any;
    private legend:any;

    componentDidMount = () => {
        this.renderDonutChart();
    };

    componentDidUpdate = () => {
        this.series.data.setAll(this.props.donutData);
        this.legend.data.setAll(this.series.dataItems);
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            document.body.style.overflow = 'visible';
        }, 500);
    };

    renderDonutChart() {

        let root = am5.Root.new("surveySurveyDonutChartDiv" + this.props.index);

        root.setThemes([
            am5themes_Animated.default.new(root)
        ]);

        let chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.horizontalLayout,
            innerRadius: am5.percent(50)
        }));

        this.series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "noOfPeople",
            categoryField: "label",
            fillField: "color",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{category}:{value}"
            })
        }));

        this.series.labels.template.setAll({
            textType: "circular",
            centerX: 0,
            centerY: 0
        });

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        this.series.data.setAll(this.props.donutData);

        // Create legend
        // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
        this.legend = chart.children.push(am5.Legend.new(root, {
            centerY: am5.percent(50),
            y: am5.percent(50),
            layout: root.verticalLayout
        }));

        this.legend.data.setAll(this.series.dataItems);

        this.series.labels.template.set("visible", false);
        this.series.ticks.template.set("visible", false);

        // Play initial series animation
        // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
        this.series.appear(1000, 100);
    }

    render() {
        return (
            <div className="p-2">
                <div className="">
                    <div className="">
                        <div className='mb-3'>
                            <div
                                key={this.props.index}
                                className="surveySurveyDonutChartDiv"
                                id={`surveySurveyDonutChartDiv${this.props.index}`}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}