import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { LOAD_ANALYSIS_ERROR,
  LOAD_SURVEY_DIALOG_TITLE,
  LOAD_SURVEY_DIALOG_DELETE_ALL_BUTTON,
  LOAD_SURVEY_DIALOG_CREATE_NEW_BUTTON,
  LOAD_SURVEY_DIALOG_CANCEL_BUTTON,
  LOAD_SURVEY_DIALOG_LOAD_BUTTON,
  LOAD_SURVEY_DIALOG_CONFIRMATION_TITLE,
  LOAD_SURVEY_DIALOG_CONFIRMATION_TEXT } from "../../common/DisplayMsgAndLabelConst";
import { TEST_HASH_PARAM } from "../../common/ConfigConst";

export class LoadSurveyDialog extends React.Component<any> {
  DELETE_ANALYSIS_BY_ID_URL: any =
    "https://askv2.makeopinion.com/?page=reporting-backend-analysis-delete-by-id" +
    TEST_HASH_PARAM();
  DELETE_ANALYSIS_BY_PROJECT_URL: any =
    "https://askv2.makeopinion.com/?page=reporting-backend-analysis-delete" +
    TEST_HASH_PARAM();

  state = {
    savedAnalaysisFilterData: [],
    selectedRowId: null,
    selectedAnalysisObj: null,
    showConfirmationModal: false,
    defaultText: "Loading...",
  };

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = (prevProps: any) => {
    if (this.props.show === true && this.props.show !== prevProps.show) {
      this.processSavedFiltersData(this.props.savedFilters);
    }
  };

  onRowClicked = (row: any, event: any) => {
    this.setState({
      selectedRowId: row.id,
      selectedAnalysisObj: row.filterObj,
    });
  };

  handleLoadAnalysisButtonClick = () => {
    if (this.state.selectedRowId != null) {
      this.props.handleLoadAnalysisButtonClick(this.state.selectedAnalysisObj);
    } else {
      this.props.showMessage("error", LOAD_ANALYSIS_ERROR());
    }
  };

  handleSingleItemDelete = (analysisId: any) => {
    fetch(this.DELETE_ANALYSIS_BY_ID_URL + "&id=" + analysisId, {
      method: "DELETE",
    })
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        this.loadData();
      })
      .catch((error) => {
        this.loadData();
        //console.error('There was an error!', error);
      });
  };

  handleAllItemsDelete = () => {
    fetch(
      this.DELETE_ANALYSIS_BY_PROJECT_URL +
        "&project_id=" +
        this.props.projectId,
      { method: "DELETE" }
    )
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        this.loadData();
        this.toggleConfirmationModal();
        this.setState({ selectedRowId: null });
      })
      .catch((error) => {
        this.loadData();
        this.toggleConfirmationModal();
        this.setState({ selectedRowId: null });
        //console.error('There was an error!', error);
      });
  };

  toggleConfirmationModal = () => {
    this.setState({ showConfirmationModal: !this.state.showConfirmationModal });
  };

  processSavedFiltersData = (savedFilters: any) => {
    try {
      let filterData: any = [];
      if (savedFilters !== null) {
        if (savedFilters.length === 0) {
          this.props.setLoadAnalysisButtonStatus(false);
        } else {
          this.props.setLoadAnalysisButtonStatus(true);
        }
        savedFilters.forEach((filter: any, i: any) => {
          let filterObj = JSON.parse(filter.filter);
          filterData.push({
            id: filter.id,
            row_created: filter.row_created,
            filterObj: filterObj,
          });
        });
      }
      this.setState({
        savedAnalaysisFilterData: filterData,
        selectedRowId: null,
        defaultText:
          filterData.length === 0 ? "There are no records to display" : "",
      });
    } catch (error) {
      return null;
    }
  };

  loadData = () => {
    this.props.loadSavedAnalysisDataFunction(this.processSavedFiltersData);
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{LOAD_SURVEY_DIALOG_TITLE()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <DataTable
                noDataComponent={this.state.defaultText}
                defaultSortAsc={false}
                defaultSortFieldId={1}
                columns={[
                  {
                    sortable: true,
                    name: "Time",
                    selector: (row: any) => row.row_created,
                  },
                  {
                    sortable: true,
                    name: "Analysis Name",
                    selector: (row: any) => row.filterObj.analysisName,
                  },
                  {
                    sortable: true,
                    width: "50px",
                    name: "",
                    cell: (event) => (
                      <div>
                        <i
                          className={`pointer fa fa-times text-danger`}
                          aria-hidden="true"
                          onClick={(e) => {
                            this.handleSingleItemDelete(event.id);
                          }}
                        ></i>
                      </div>
                    ),
                  },
                ]}
                data={this.state.savedAnalaysisFilterData}
                pagination
                onRowClicked={this.onRowClicked}
                selectableRowsHighlight={true}
                conditionalRowStyles={[
                  {
                    when: (row) => row.id == this.state.selectedRowId,
                    style: {
                      backgroundColor: "#EEE",
                    },
                  },
                ]}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="me-auto"
              variant="danger"
              onClick={(e) => {
                this.toggleConfirmationModal();
              }}
            >
              {LOAD_SURVEY_DIALOG_DELETE_ALL_BUTTON()}
            </Button>

            {this.props.showCreateNewButton ? (
              <Button
                variant="secondary"
                onClick={(e) => {
                  this.props.onHide();
                }}
              >
                {LOAD_SURVEY_DIALOG_CREATE_NEW_BUTTON()}
              </Button>
            ) : null}

            {!this.props.showCreateNewButton ? (
              <Button
                variant="secondary"
                onClick={(e) => {
                  this.props.onHide();
                }}
              >
                {LOAD_SURVEY_DIALOG_CANCEL_BUTTON()}
              </Button>
            ) : null}

            <Button
              variant="primary"
              disabled={this.state.selectedRowId === null}
              onClick={(e) => {
                this.handleLoadAnalysisButtonClick();
              }}
            >
              {LOAD_SURVEY_DIALOG_LOAD_BUTTON()}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirmationModal}>
          <Modal.Header closeButton>
            <Modal.Title>{LOAD_SURVEY_DIALOG_CONFIRMATION_TITLE()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h5>{LOAD_SURVEY_DIALOG_CONFIRMATION_TEXT()}</h5>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                this.toggleConfirmationModal();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                this.handleAllItemsDelete();
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
