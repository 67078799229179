import React from "react";

export class FullPageError extends React.Component<any> {
  render() {
    return (
      <div className="p-5">
        <div className="alert alert-danger" role="alert">
          {this.props.error}
        </div>
      </div>
    );
  }
}
