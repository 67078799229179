import React from "react";
import DataTable from "react-data-table-component";
import { Box } from "@mui/material";
import {FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN} from "../common/DisplayMsgAndLabelConst";

export const INVOCATION_MODE_DASHBOARD = "INVOCATION_MODE_DASHBOARD";
export const INVOCATION_MODE_ANSWER_DETAIL = "INVOCATION_MODE_ANSWER_DETAIL";

export class SurveyTableComponent extends React.Component<any> {

  private customStyles = {
    rows: {
        style: {
            marginTop: '10px', // override the row height
        },
    },
   
};

  private dataTableComponentKey: number = 1;
  state = {
    rowsPerPage: this.props.answerTableSettings.rowsPerPage,
    defaultPage: this.props.answerTableSettings.defaultPage,
    hiddenRow: true,
  };

  handleAnswerRowDoubleClick = (
    questionId: any,
    answerId: any,
    columnId: any
  ) => {
    this.props.handleSelectChange(questionId, answerId, columnId);
  };

  componentDidUpdate = (prevProps: any) => {
    if (
      this.props.answerTableSettings.rowsPerPage !== this.state.rowsPerPage ||
      this.props.answerTableSettings.defaultPage !== this.state.defaultPage
    ) {
      this.setState({
        rowsPerPage: this.props.answerTableSettings.rowsPerPage,
        defaultPage: this.props.answerTableSettings.defaultPage,
      });
      //This will forefully re-create the DataTable component
      this.dataTableComponentKey++;
    }
  };
  onHiddenRow = (getValues?: any) => {
    console.log(getValues, this.props.tableData, "getValues...");
    this.props.tableData.forEach((items?: any, index?: any) => {
      if (items.answerId === getValues.answerId) {
        if (items.includeInResult === true) {
          items["includeInResult"] = false;
        } else {
          items["includeInResult"] = true;
        }
      }
    });
    console.log(this.props.tableData, "this.props.tableData...");
    if (this.state.hiddenRow === true) {
      this.setState({ hiddenRow: false });
    } else {
      this.setState({ hiddenRow: true });
    }
  };
  render() {
    return (
      <div className="table-responsive survey-table table-bordered">
        {this.props.showTable ? (
          <div>
            <DataTable
              key={this.dataTableComponentKey}
              pagination={this.props.showPagination}
              paginationDefaultPage={this.state.defaultPage}
              paginationPerPage={this.state.rowsPerPage}
              
              onChangePage={(page: number, totalRows: number) => {
                // this.state.defaultPage = page;
                if(this.props.displaySamePageAllColumns){
                  this.setState({ defaultPage: page });
                  if (this.props.invocationMode === INVOCATION_MODE_DASHBOARD) {
                    this.props.onChangePage(page);
                  }
                }
              }}
              paginationRowsPerPageOptions={
                this.props.answerTableSettings.rowsPerPageOptions
              }
              onChangeRowsPerPage={(
                currentRowsPerPage: number,
                currentPage: number
              ) => {
                this.setState({ rowsPerPage: currentRowsPerPage });
                // this.state.rowsPerPage = currentRowsPerPage;
                if (this.props.invocationMode === INVOCATION_MODE_DASHBOARD) {
                  this.props.onChangeRowsPerPage(currentRowsPerPage);
                }
              }}
              columns={[
                // {
                //   omit: this.props.hideChangeFilterColumn,
                //   sortable: true,
                //   center: true,
                //   width: "100px",
                //   name: "",
                //   cell: (event?: any) => (
                //     <div>
                //       <Box
                //         sx={{
                //           color: event.includeInResult ? "" : "text-muted",
                //         }}
                //       >
                //         {event.numberOfPeopleAnswered}
                //       </Box>
                //     </div>
                //   ),
                // },
                {
                  name:<span className="font-15">{FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN()}</span>,
                  sortable: true,
                  selector: (row: any) => row.percentageOfPeopleAnswered,
                  cell: (rowData: any) => (
                    <div className="progress-div">
                      <Box>{rowData.selectedAnswerText}</Box>
                      <div
                        className="progress"
                        style={{ height: 20, marginTop: 10, marginBottom: 10 }}
                      >
                        {rowData.percentageOfPeopleAnswered <= 5 ? (
                          <div
                            className={
                              rowData.includeInResult
                                ? `progress-bar progress-bar-low`
                                : `progress-bar ${"hiddenColor"}`
                            }
                            role="progressbar"
                            style={{
                              width: `${rowData.percentageOfPeopleAnswered}%`,
                            }}
                          ></div>
                        ) : rowData.percentageOfPeopleAnswered > 5 &&
                          rowData.percentageOfPeopleAnswered <= 40 ? (
                          <div
                            className={
                              rowData.includeInResult
                                ? `progress-bar progress-bar-med`
                                : `progress-bar ${"hiddenColor"}`
                            }
                            role="progressbar"
                            style={{
                              width: `${rowData.percentageOfPeopleAnswered}%`,
                            }}
                          ></div>
                        ) : (
                          <div
                            className={
                              rowData.includeInResult
                                ? `progress-bar progress-bar-high`
                                : `progress-bar ${"hiddenColor"}`
                            }
                            role="progressbar"
                            style={{
                              width: `${rowData.percentageOfPeopleAnswered}%`,
                            }}
                          ></div>
                        )}

                        <Box
                          className="progress-bar-text"
                          sx={{
                            width: "100%",
                            textAlign: "right",
                            fontSize: 14,
                          }}
                        >
                          <a
                            className="text-dark pointer"
                            onClick={(e) => {
                              this.props.handleAnswerDetailLinkClick(
                                rowData.answerId
                              );
                            }}
                          >
                            {rowData.percentageOfPeopleAnswered}% (
                            {rowData.numberOfPeopleAnswered})
                          </a>
                        </Box>
                      </div>
                    </div>
                  ),
                },
                {
                  sortable: false,
                  name: <i
                  className={`fas fa-times pointer fa-2x column-delete-icon
                  ${this.props.selectedColumn ? "text-primary"
                  : ""}`}
                  onClick={(e) => {
                    this.props.handleCloseButtonClick(
                      this.props.columnId
                    );
                  }}
                ></i>,
                  width: "100px",
                  center: true,
                  cell: (rowData: any) => (
                    <div>
                      <Box sx={{ marginTop: 2 }}>
                        <a
                          className="pointer"
                          onClick={(e) => {
                            this.handleAnswerRowDoubleClick(
                              this.props.questionVsAnswerEntry.question.id,
                              rowData.answerId,
                              this.props.questionVsAnswerEntry.columnId
                            );
                          }}
                        >
                          <i
                            className={
                              rowData.includeInResult
                                ? "fa-solid fa-toggle-on fa-20 successoption"
                                : "fa-solid fa-toggle-off fa-20"
                            }
                          ></i>
                        </a>
                      </Box>
                    </div>
                  ),
                  // selector: (row: any) => row.numberOfPeopleAnswered,
                },
              ]}
              data={this.props.tableData}
              conditionalRowStyles={[
                {
                  when: (row) => !row.includeInResult,
                  style: {
                    backgroundColor: "#EEE",
                  },
                },
              ]}
              customStyles={
                this.customStyles
              }
            />
            {/* <Box className={
              this.props.showPagination === false ? 'top-5' : ''
            }
            sx={{ display: "flex", position: "relative", bottom: "39px" }}>
              <Box sx={{ marginLeft: 2, marginRight: 1 }}>
                <i
                  className="fas fa-circle"
                  aria-hidden="true"
                  style={{ color: "#00a4ff" }}
                ></i>{" "}
                Mean
              </Box>
              <Box sx={{ marginLeft: 2, marginRight: 1 }}>
                <i
                  className="fas fa-circle"
                  aria-hidden="true"
                  style={{ color: "#007cc2" }}
                ></i>{" "}
                Median
              </Box>
              <Box sx={{ marginLeft: 2, marginRight: 1 }}>
                <i
                  className="fas fa-circle"
                  aria-hidden="true"
                  style={{ color: "#005788" }}
                ></i>{" "}
                Median
              </Box>
            </Box> */}
          </div>
        ) : null}
      </div>
    );
  }
}

export default SurveyTableComponent;
