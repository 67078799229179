import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ZoomControl } from "@amcharts/amcharts5/map";

export class SurveyBarChartComponent extends React.Component<any>{

    private series: any;
    private xAxis: any;

    componentDidMount = () => {
        this.renderBarChart();
    };

    componentDidUpdate = () => {
        this.xAxis.data.setAll(this.props.barData);
        this.series.data.setAll(this.props.barData);
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            document.body.style.overflow = 'visible';
        }, 500);
    };

    renderBarChart() {

        let root = am5.Root.new("surveySurveyBarChartDiv" + this.props.index);
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.default.new(root)
        ]);
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            pinchZoomX: false
        }));

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
        xRenderer.labels.template.setAll({
            rotation: -90,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15
        });

        this.xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "label",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        

        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        this.series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series 1",
            xAxis: this.xAxis,
            yAxis: yAxis,
            valueYField: "noOfPeople",
            sequencedInterpolation: true,
            categoryXField: "label",

            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            })
        }));

        this.series.columns.template.setAll({
            tooltipText: "{categoryX}: {valueY}",
            tooltipY: 0,
            strokeOpacity: 0,
            templateField: "columnSettings"
        });

        this.xAxis.data.setAll(this.props.barData);
        this.series.data.setAll(this.props.barData);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        this.series.appear(1000);
        chart.appear(1000, 100);
    }

    render() {
        return (
            <div className="p-2">
                <div className="">
                    <div className="">
                        <div className='mb-3'>
                            <div
                                key={this.props.index}
                                className="surveySurveyBarChartDiv"
                                id={`surveySurveyBarChartDiv${this.props.index}`}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}