import React from "react";
import Modal from "react-bootstrap/Modal";
import { 
  QUESTIONS_LIST_DIALOG_TITLE } from "../../common/DisplayMsgAndLabelConst";

export class QuestionsListDialog extends React.Component<any> {

  scrollToQuestion = (questionId:any) =>{
    this.props.onHide();
    setTimeout(() => {
        this.props.scrollToQuestion(questionId);    
    }, 1500);
    
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          backdrop="static"
          keyboard={false}
          onHide={this.props.onHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>{QUESTIONS_LIST_DIALOG_TITLE()}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-height-400">
            <ul className="list-group">
              {this.props.questions
                ? Array.from(this.props.questions.values()).map(
                    (questionObj: any, index: any) => {
                      return (
                        <li className="list-group-item pointer" key={index}
                        onClick={(e) =>{
                            this.scrollToQuestion(questionObj.question.id);
                        }}
                        >
                          {questionObj.question.question_text}
                        </li>
                      );
                    }
                  )
                : null}
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
