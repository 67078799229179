import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldHigh from "@amcharts/amcharts5-geodata/worldHigh";

export class SurveyGeoMapComponent extends React.Component<any> {
  private geoMapData: any;

  componentDidMount = () => {
    this.renderGeoMap();
  };

  componentDidUpdate = (prevProps: any) => {
    this.refreshGeoMapData();
  };

  refreshGeoMapData() {
    if (this.geoMapData == null) {
      return;
    }
    this.geoMapData.clear();
    if (
      this.props.geoTypeAnswerArray == null ||
      this.props.geoTypeAnswerArray.length == 0
    ) {
      return;
    }
    for (let i = 0; i < this.props.geoTypeAnswerArray.length; i++) {
      this.geoMapData.push(this.props.geoTypeAnswerArray[i].coordinates);
    }
  }

  renderGeoMap() {
    let root = am5.Root.new("surveyGeoMapDiv" + this.props.index);
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        projection: am5map.geoNaturalEarth1(),
      })
    );

    chart.set("zoomControl", am5map.ZoomControl.new(root, {}));


    // Create polygon series
    let polygonSeries: any = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldHigh,
        exclude: ["AQ"],
      })
    );

    let pointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        latitudeField: "latitude",
        longitudeField: "longitude",
      })
    );

    this.geoMapData = pointSeries.data;

    pointSeries.bullets.push(function () {
      var container = am5.Container.new(root, {});
      var circle = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: am5.color(0xff0000),
          strokeOpacity: 0,
          fillOpacity: 0.65,
        })
      );

      var circle2 = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: am5.color(0xff0000),
          strokeOpacity: 0,
          fillOpacity: 0.65,
        })
      );

      circle.animate({
        key: "scale",
        from: 1,
        to: 5,
        duration: 600,
        easing: am5.ease.out(am5.ease.cubic),
        loops: 2,
      });
      circle.animate({
        key: "opacity",
        from: 1,
        to: 0,
        duration: 600,
        easing: am5.ease.out(am5.ease.cubic),
        loops: 2,
      });

      return am5.Bullet.new(root, {
        sprite: container,
      });
    });
    this.refreshGeoMapData();
  }

  render() {
    return (
      <div className="p-2">
        <div className="">
          <div className="">
            <div className='mb-3'>
              <div
                key={this.props.index}
                className="surveyGeoMapDiv"
                id={`surveyGeoMapDiv${this.props.index}`}
              ></div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
