import React from "react";
import { SurveyGeoMapComponent } from "./SurveyGeoMapComponent";
import SurveyGraphComponent from "./SurveyGraphComponent";
import SurveyTableComponent, {
  INVOCATION_MODE_DASHBOARD,
} from "./SurveyTableComponent";
import SurveyFreeTextAnswersTableComponent from "./SurveyFreeTextAnswersTableComponent";
import { QUESTION_TYPE_LABEL,
  ADD_COLUMN_NEW_BUTTON,
  ADD_COLUMN_DUPLICATE_BUTTON } from "../common/DisplayMsgAndLabelConst";
import { ScrollSyncPane } from "react-scroll-sync";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Collapse from "react-bootstrap/Collapse";
import ReactTooltip from "react-tooltip";
import { Box } from "@mui/material";

import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";

export class SurveyInfoRowComponent extends React.Component<any> {
  state = {
    isPlusButtonOpen: false,
    showTable: true,
    showBarChart: false,
    showPieChart: false,
    showModal: false,
    columns: [],
    answerCollapseOpen: true,
    localAnswerTableSettings: {
      rowsPerPage: this.props.answerTableSettings.rowsPerPage,
      rowsPerPageOptions: this.props.answerTableSettings.rowsPerPageOptions,
      defaultPage: 1,
    },
    plusButtonBottom:"54%"
  };

  handleColumnClick = (columnId: any) => {
    this.props.columnChangeHandler(columnId);
  };

  handleAddColumnClick = (columnIndex: number) => {
    this.setState({isPlusButtonOpen:!this.state.isPlusButtonOpen});
    this.setState({});
    this.props.handleRowComponentAddColumnButtonClick(columnIndex);
  };

  handleAddColumnCopyClick =()=>{
    this.setState({isPlusButtonOpen:!this.state.isPlusButtonOpen});
    this.setState({});
    this.props.handleAddColumnClick(true);
  }

  handleCloseButtonClick = (columnId: any) => {
    this.props.closeButtonClickHandler(columnId);
  };

  handleShowOrHideTableClick = () => {
    this.setState({ showTable: !this.state.showTable });
    // this.state.showTable = !this.state.showTable;
    this.setState({});
  };

  handleShowOrHideBarChartClick = () => {
    this.setState({ showBarChart: !this.state.showBarChart });
    // this.state.showBarChart = !this.state.showBarChart;
    this.setState({});
  };

  handleShowOrHidePieChartClick = () => {
    this.setState({ showPieChart: !this.state.showPieChart });
    // this.state.showPieChart = !this.state.showPieChart;
    this.setState({});
  };

  handleSelectChange = (questionId: any, answerId: any, columnId: any) => {
    this.props.handleSelectChange(questionId, answerId, columnId);
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (this.props.answerTableSettings.updateRequired === true) {
      this.setState({
        localAnswerTableSettings: {
          rowsPerPage: this.props.answerTableSettings.rowsPerPage,
          rowsPerPageOptions: this.props.answerTableSettings.rowsPerPageOptions,
        },
      });
    }
  };

  onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    this.props.onColumnDragHandler(parseInt(draggableId), destination);
  };

  handleAnswerDetailLinkClick = (questionId: number, answerId?: number) => {
    this.props.handleAnswerDetailLinkClick(
      this.props.selectedColumnId,
      questionId,
      answerId
    );
  };

  toggleCollapse = () => {
    this.setState({ answerCollapseOpen: !this.state.answerCollapseOpen });
    // this.state.answerCollapseOpen = !this.state.answerCollapseOpen;
    this.setState({});
  };

  onChangeRowsPerPage = (currentRowsPerPage: number) => {
    this.setState({
      localAnswerTableSettings: { rowsPerPage: currentRowsPerPage },
    });
    // this.state.localAnswerTableSettings.rowsPerPage = currentRowsPerPage;
    this.setState({});
  };

  onChangePage = (pageNo: number) => {
    if (this.props.settings.answerTable.displaySamePageAllColumns) {
      this.setState({ localAnswerTableSettings: { defaultPage: pageNo } });
      // this.state.localAnswerTableSettings.defaultPage = pageNo;
      this.setState({});
    }
  };

  calculateBottomValueForPlusBtn = (questionId:number) : string =>{
    const element = document.querySelector('#questionRowDiv'+questionId);
    if(element !== null){
      let height = element.clientHeight;
      let bottom = ((height/2)-40)+"px";
      return bottom;
    }
    return "";
  }

  render() {
    return (
      <div
        className="col-12 mb-3 question-glow-effect"
        key={this.props.index}
        id={`questionRowDiv${this.props.question.id}`}
      >
        <ReactTooltip
          type="dark"
          effect="solid"
          html={true}
          className="tooltip-word-break"
        />
        <div className="card">
          <div className="card-header p-5 question-title-header">
            <div className="question-text">
              <Box
                className="card-label fw-bolder fs-3 mb-1"
                sx={{ fontWeight: "bold" }}
              >
                {this.props.question.question_text}
              </Box>
              <Box className="mt-1 fw-bold fs-6 text-muted">
                {QUESTION_TYPE_LABEL[this.props.question.question_type]}
              </Box>
            </div>
            <div className="card-title align-items-start flex-column">
              {this.props.question.question_type === "single_punch" ||
              this.props.question.question_type === "multi_punch" ? (
                <ul className="nav">
                  <li className="nav-item">
                    <span
                      className="pointer p-2"
                      data-tip="Show Table"
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <i
                        className={`fas fa-list-alt fa-lg
                    ${
                      this.state.showTable === true ? "text-blue" : "text-muted"
                    }
                    `}
                        aria-hidden="true"
                        onClick={(e) => {
                          this.handleShowOrHideTableClick();
                        }}
                      ></i>
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className="pointer p-2"
                      data-tip="Show Bar Chart"
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <i
                        className={`fas fa-chart-bar fa-lg ${
                          this.state.showBarChart === true
                            ? "text-blue"
                            : "text-muted"
                        }
                    `}
                        aria-hidden="true"
                        onClick={(e) => {
                          this.handleShowOrHideBarChartClick();
                        }}
                      ></i>
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className="pointer p-2"
                      data-tip="Show Pie Chart"
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <i
                        className={`fas fa-chart-pie fa-lg ${
                          this.state.showPieChart === true
                            ? "text-blue"
                            : "text-muted"
                        } aria-hidden="true"`}
                        onClick={(e) => {
                          this.handleShowOrHidePieChartClick();
                        }}
                      ></i>
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className="pointer p-2"
                      data-tip="Open Modal"
                      style={{ marginLeft: 5, marginRight: 5 }}
                    >
                      <i
                        className={`fas fa-exclamation-circle fa-lg
                    `}
                        aria-hidden="true"
                        onClick={(e) => {
                          this.handleAnswerDetailLinkClick(
                            this.props.question.id
                          );
                        }}
                      ></i>
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="pointer" data-tip="caret up">
                      <Box
                        sx={{
                          borderLeft: "2px solid #ccc",
                          height: 30,
                          marginLeft: 2,
                          marginRight: 2,
                        }}
                      ></Box>
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="pointer p-2" data-tip="scroll to filter">
                      <i
                        className={`fa-solid fa-window-restore fa-lg`}
                        aria-hidden="true"
                        onClick={(e) => {
                          this.props.onSurveyInfoRowComponentClick(
                            this.props.question.id
                          );
                        }}
                      ></i>
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="pointer p-2" data-tip="Collapse">
                      <svg
                        onClick={(e) => {
                          this.toggleCollapse();
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        className={`${
                          this.state.answerCollapseOpen === false
                            ? "rotate-180"
                            : ""
                        }`}
                        version="1.1"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        width="31"
                        height="31"
                        viewBox="0 0 292.362 292.362"
                        style={{
                          marginTop: "-1px",
                          fill: "#a1a5b7",
                        }}
                      >
                        <g xmlns="http://www.w3.org/2000/svg">
                          <path d="M286.935,197.286L159.028,69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233,1.807-12.85,5.424L5.424,197.286   C1.807,200.9,0,205.184,0,210.132s1.807,9.233,5.424,12.847c3.621,3.617,7.902,5.428,12.85,5.428h255.813   c4.949,0,9.233-1.811,12.848-5.428c3.613-3.613,5.427-7.898,5.427-12.847S290.548,200.9,286.935,197.286z" />
                        </g>
                      </svg>

                      {/* <i
                        className={`fas fa-lg
                        ${
                          this.state.answerCollapseOpen === true
                            ? "fa-caret-up"
                            : "fa-caret-down"
                        }
                    `}
                        aria-hidden="true"
                        onClick={(e) => {
                          this.toggleCollapse();
                        }}
                      ></i> */}
                    </span>
                  </li>
                </ul>
              ) : (
                <ul className="nav">
                  <li className="nav-item">
                    <span className="pointer p-2" data-tip="Collapse">
                      <i
                        className={`fas fa-lg 
                        ${
                          this.state.answerCollapseOpen === true
                            ? "fa-caret-up"
                            : "fa-caret-down"
                        }
                    `}
                        aria-hidden="true"
                        onClick={(e) => {
                          this.toggleCollapse();
                        }}
                      ></i>
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        <Collapse in={this.state.answerCollapseOpen}>
          <div className=" mb-2">
            <ScrollSyncPane group="horizontal">
              <DragDropContext onDragEnd={this.props.onDragEnd}>
                <Droppable
                  droppableId={"drop" + this.props.index}
                  direction="horizontal"
                >
                  {(proivded) => (
                    <div
                      className={this.props.scrollPage}
                      ref={proivded.innerRef}
                    >
                      <div className="d-flex">
                        {Array.from(
                          this.props.columnVsQuestionVsAnswerMap.values()
                        ).map((questionVsAnswerEntry: any, index: any) => {
                          let questionAnsMapObj = questionVsAnswerEntry.get(
                            this.props.question.id
                          );
                          return (
                            <Draggable
                              draggableId={"" + questionAnsMapObj.columnId}
                              index={index}
                              key={questionAnsMapObj.columnId}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  id={"answerRowDiv"+questionAnsMapObj.question.id}
                                  className={` ${
                                    this.props.columnVsQuestionVsAnswerMap
                                      .size === 1
                                      ? "col-xl-12 col-md-12 full-width"
                                      : "col-12 col-xl-6 col-md-6"
                                  }  col-sm-12 col-xs-12 p-7 grey-bg`}
                                  key={`containerDiv${
                                    questionAnsMapObj.columnId +
                                    "-" +
                                    questionAnsMapObj.question.id
                                  }`}
                                  onClick={(e) => {
                                    this.handleColumnClick(
                                      questionAnsMapObj.columnId
                                    );
                                  }}
                                >
                                  <div
                                    className={`card h-100 ${
                                      this.props.selectedColumnId ===
                                      questionAnsMapObj.columnId
                                        ? "border-primary"
                                        : ""
                                    }`}
                                  >
                                    {/* <div
                                      className={`card-header table-card-header table-item-header pt-2 pb-2 ${
                                        this.props.selectedColumnId ===
                                        questionAnsMapObj.columnId
                                          ? ""
                                          : ""
                                      }`}
                                    >
                                      <div className="card-title align-items-start flex-column">
                                        <Box>Answer</Box>
                                      </div>
                                      <div className="card-toolbar">
                                        <i
                                          className={`fas fa-times pointer fa-2x column-delete-icon ${
                                            this.props.selectedColumnId ===
                                            questionAnsMapObj.columnId
                                              ? "text-primary"
                                              : ""
                                          }`}
                                          onClick={(e) => {
                                            this.handleCloseButtonClick(
                                              questionAnsMapObj.columnId
                                            );
                                          }}
                                        ></i>
                                      </div>
                                    </div> */}

                                    <div className="card-body p-1">
                                      {this.props.question.question_type ===
                                        "single_punch" ||
                                      this.props.question.question_type ===
                                        "multi_punch" ? (
                                        <div>
                                          <SurveyTableComponent
                                            columnId={
                                              questionAnsMapObj.columnId
                                            }
                                            handleCloseButtonClick={
                                              this.handleCloseButtonClick
                                            }
                                            selectedColumn={
                                              this.props.selectedColumnId ===
                                              questionAnsMapObj.columnId
                                                ? true
                                                : false
                                            }
                                            displaySamePageAllColumns={
                                              this.props.settings.answerTable
                                                .displaySamePageAllColumns
                                            }
                                            invocationMode={
                                              INVOCATION_MODE_DASHBOARD
                                            }
                                            showPagination={true}
                                            answerDetailsNavigation={true}
                                            onChangeRowsPerPage={
                                              this.onChangeRowsPerPage
                                            }
                                            onChangePage={this.onChangePage}
                                            answerTableSettings={
                                              this.state
                                                .localAnswerTableSettings
                                            }
                                            key={`surveyTableComponent${
                                              questionAnsMapObj.columnId +
                                              "-" +
                                              questionAnsMapObj.question.id
                                            }`}
                                            showTable={this.state.showTable}
                                            handleAnswerDetailLinkClick={(
                                              answerId: number
                                            ) => {
                                              this.handleAnswerDetailLinkClick(
                                                questionAnsMapObj.question.id,
                                                answerId
                                              );
                                            }}
                                            tableData={
                                              questionAnsMapObj.answerMap &&
                                              questionAnsMapObj.answerMap.size >
                                                0
                                                ? Array.from(
                                                    questionAnsMapObj.answerMap.values()
                                                  ).sort(
                                                    (val1: any, val2: any) => {
                                                      return (
                                                        val1.answerId -
                                                        val2.answerId
                                                      );
                                                    }
                                                  )
                                                : null
                                            }
                                            questionVsAnswerEntry={
                                              questionAnsMapObj
                                            }
                                            handleSelectChange={
                                              this.handleSelectChange
                                            }
                                          />
                                          <SurveyGraphComponent
                                            index={
                                              questionAnsMapObj.columnId +
                                              "-" +
                                              questionAnsMapObj.question.id
                                            }
                                            key={`surveyGraphComponent${
                                              questionAnsMapObj.columnId +
                                              "-" +
                                              questionAnsMapObj.question.id
                                            }`}
                                            showBarChart={
                                              this.state.showBarChart
                                            }
                                            showPieChart={
                                              this.state.showPieChart
                                            }
                                            consolidatedData={
                                              questionAnsMapObj.consolidatedData
                                            }
                                          />
                                        </div>
                                      ) : null}

                                      {this.props.question.question_type ===
                                      "GEO_IP_LAT_LNG" ? (
                                        <SurveyGeoMapComponent
                                          index={
                                            questionAnsMapObj.columnId +
                                            "-" +
                                            questionAnsMapObj.question.id
                                          }
                                          selectedColumnId={
                                            this.props.selectedColumnId
                                          }
                                          columnId={questionAnsMapObj.columnId}
                                          key={`surveyGeoMapComponent${
                                            questionAnsMapObj.columnId +
                                            "-" +
                                            questionAnsMapObj.question.id
                                          }`}
                                          geoTypeAnswerArray={
                                            questionAnsMapObj.geoTypeAnswerArray
                                          }
                                        />
                                      ) : null}

                                      {this.props.question.question_type ===
                                      "open_ended" ? (
                                        <div>
                                          <SurveyFreeTextAnswersTableComponent
                                            onChangePage={this.onChangePage}
                                            key={`surveyFreeTextAnswersTableComponent${
                                              questionAnsMapObj.columnId +
                                              "-" +
                                              questionAnsMapObj.question.id
                                            }`}
                                            openEndedAnswerArray={
                                              questionAnsMapObj.openEndedAnswerArray
                                            }
                                            answerTableSettings={
                                              this.state
                                                .localAnswerTableSettings
                                            }
                                            onChangeRowsPerPage={
                                              this.onChangeRowsPerPage
                                            }
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  {this.props.selectedColumnId ===
                                  questionAnsMapObj.columnId ? (
                                    <Box
                                      sx={{ float: "right", bottom:this.calculateBottomValueForPlusBtn(questionAnsMapObj.question.id) }}
                                      className="plus-button plus-circle-btn pointer"
                                      // onClick={(event: any) => {
                                      //   this.handleAddColumnClick(index);
                                      // }}
                                    >
                                      <FloatingMenu
                                        slideSpeed={500}
                                        spacing={8}
                                        isOpen={this.state.isPlusButtonOpen}
                                      >
                                        <MainButton
                                          iconResting={
                                            <svg
                                              width="50"
                                              height="50"
                                              viewBox="0 0 50 50"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle
                                                style={{ fill: "#439ef0" }}
                                                cx="25"
                                                cy="25"
                                                r="25"
                                              />
                                              <line
                                                style={{
                                                  fill: "none",
                                                  stroke: "#FFFFFF",
                                                  strokeWidth: "3",
                                                  strokeLinecap: "round",
                                                  strokeLinejoin: "round",
                                                  strokeMiterlimit: "10",
                                                }}
                                                x1="25"
                                                y1="13"
                                                x2="25"
                                                y2="38"
                                              />
                                              <line
                                                style={{
                                                  fill: "none",
                                                  stroke: "#FFFFFF",
                                                  strokeWidth: "3",
                                                  strokeLinecap: "round",
                                                  strokeLinejoin: "round",
                                                  strokeMiterlimit: "10",
                                                }}
                                                x1="37.5"
                                                y1="25"
                                                x2="12.5"
                                                y2="25"
                                              />
                                            </svg>
                                          }
                                          iconActive={
                                            <svg
                                              width="50"
                                              height="50"
                                              viewBox="0 0 50 50"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle
                                                style={{ fill: "#439ef0" }}
                                                cx="25"
                                                cy="25"
                                                r="25"
                                              />
                                              <line
                                                style={{
                                                  fill: "none",
                                                  stroke: "#FFFFFF",
                                                  strokeWidth: "3",
                                                  strokeLinecap: "round",
                                                  strokeLinejoin: "round",
                                                  strokeMiterlimit: "10",
                                                }}
                                                x1="37.5"
                                                y1="25"
                                                x2="12.5"
                                                y2="25"
                                              />
                                            </svg>
                                          }
                                          background="none"
                                          onClick={() =>
                                            this.setState({
                                              isPlusButtonOpen: !this.state.isPlusButtonOpen,
                                            })
                                          }
                                          size={56}
                                        />
                                        <ChildButton
                                          data-tip={ADD_COLUMN_NEW_BUTTON()}
                                          icon={
                                            <i className="fa-solid fa-right-from-bracket text-white"></i>
                                          }
                                          background="rgb(67, 158, 240)"
                                          size={40}
                                          onClick={() =>
                                            this.handleAddColumnClick(index)
                                          }
                                        />
                                        <ChildButton
                                          data-tip={ADD_COLUMN_DUPLICATE_BUTTON()}
                                          icon={
                                            <i className="fa-solid fa-copy text-white"></i>
                                          }
                                          background="rgb(67, 158, 240)"
                                          size={40}
                                          onClick={() =>
                                            this.handleAddColumnCopyClick()
                                          }
                                        />
                                      </FloatingMenu>
                                    </Box>
                                  ) : null}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {proivded.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ScrollSyncPane>
          </div>
        </Collapse>
      </div>
    );
  }
}
