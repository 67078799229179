import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SurveyTableComponent, {
  INVOCATION_MODE_ANSWER_DETAIL,
} from "../SurveyTableComponent";
import DataTable from "react-data-table-component";
import { UserSurveyDetailsDialog } from "./UserSurveyDetailsDialog";
import { QUESTION_TYPE_LABEL } from "../../common/DisplayMsgAndLabelConst";
import ReactTooltip from "react-tooltip";
import { TEST_HASH_PARAM } from "../../common/ConfigConst";

export const INVOCATION_MODE_ANSWER = "INVOCATION_MODE_ANSWER";
export const INVOCATION_MODE_QUESTION = "INVOCATION_MODE_QUESTION";

export class AnswerDetailsDialog extends React.Component<any> {
  private LOAD_USER_DETAILS_URL: string =
    "https://askv2.makeopinion.com/?page=reporting-backend-answers-filter"+TEST_HASH_PARAM();

  state = {
    userIdVsSurveyDetailsDialogs: new Map(),
    userDetailTableData: [],
    userDetailTableOverview: {
      number_of_users: 0,
    },
    userTableLoading: false,
    pagination: {
      currentPage: 1,
      rowsPerPage: this.props.answerTableSettings.rowsPerPage,
    },
    questionIdVsUserIdVsAnswers: new Map(),
    columns: [],
    questionColumnsShowValMap: new Map(),
    questionColumnsObjectMap: new Map(),
  };

  private userDetailTableKey: number = 1;

  private scrollToPosition: any;

  componentDidUpdate = (prevProps: any) => {
    if (
      this.props.show === true &&
      (this.props.show !== prevProps.show ||
        this.props.selectedAnswerIds !== prevProps.selectedAnswerIds)
    ) {
      this.loadData();
    }
    if (this.scrollToPosition !== null) {
      this.scrollToDiv(this.scrollToPosition);
      this.scrollToPosition = null;
    }
  };

  getAnswerFilterDetails = (): any => {
    return {
      answerFilters: [
        {
          selectedQuestionId: this.props.selectedQuestionId,
          selectedAnswerIds: this.props.selectedAnswerIds,
        },
      ],
      pagination: {
        startIndex:
          (this.state.pagination.currentPage - 1) *
          this.state.pagination.rowsPerPage,
        itemsPerPage: this.state.pagination.rowsPerPage,
      },
      sort: {
        column: "username",
        ascending: true,
      },
    };
  };

  loadData = () => {
    this.setState({ userTableLoading: true });
    let answerFilterDetails = this.getAnswerFilterDetails();
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(answerFilterDetails),
    };
    fetch(
      this.LOAD_USER_DETAILS_URL + "&project_id=" + this.props.projectId,
      requestOptions
    )
      .then((response) => response.json())
      .then((data: any) => {
        let questionIdSet: any = new Set();
        this.state.questionIdVsUserIdVsAnswers = new Map();

        for (let i = 0; i < data.results.length; i++) {
          for (const [key, value] of Object.entries(data.results[i])) {
            let valObj: any = value;
            if (!this.state.questionIdVsUserIdVsAnswers.has(valObj.user_id)) {
              this.state.questionIdVsUserIdVsAnswers.set(
                valObj.user_id,
                new Map()
              );
            }
            if (
              !this.state.questionIdVsUserIdVsAnswers
                .get(valObj.user_id)
                .has(valObj.question_id)
            ) {
              this.state.questionIdVsUserIdVsAnswers
                .get(valObj.user_id)
                .set(valObj.question_id, []);
            }
            this.state.questionIdVsUserIdVsAnswers
              .get(valObj.user_id)
              .get(valObj.question_id)
              .push(valObj);
            questionIdSet.add(valObj.question_id);
          }
        }

        let columns: any = [];
        columns.push({
          sortable: false,
          name: "User ID",
          width: "120px",
          //right: true,
          cell: (rowData: any) => (
            <div className="text-center">
              {rowData[0] !== null &&
              rowData[0] !== undefined &&
              rowData[0].hasOwnProperty("user_id") ? (
                <span>{rowData[0].user_id}</span>
              ) : (
                ""
              )}
            </div>
          ),
        });
        columns.push({
          sortable: false,
          name: "",
          width: "50px",
          //right: true,
          cell: (rowData: any) => (
            <div className="text-center">
              {rowData[0] !== null &&
              rowData[0] !== undefined &&
              rowData[0].hasOwnProperty("user_id") ? (
                <span>
                  <i
                    className="fa-solid fa-magnifying-glass ml-5 pointer"
                    onClick={(event: any) => {
                      this.handleUserSurveyDetailsLinkClick(rowData[0].user_id);
                    }}
                  ></i>
                </span>
              ) : (
                ""
              )}
            </div>
          ),
        });

        let i = 0;
        this.state.questionColumnsShowValMap = new Map();
        for (let questionId of questionIdSet) {
          this.state.questionColumnsShowValMap.set(questionId, true);
          let columnObj: any = {
            sortable: false,
            name: <div>{this.getQuestionColumnContent(questionId, ++i)}</div>,
            actualWidth: 200,
            width: "200px",
            cell: (rowData: any) => (
              <div className="pt-1 pb-1">
                {this.state.questionColumnsShowValMap.get(questionId) &&
                rowData[0] !== null &&
                rowData[0] !== undefined &&
                rowData[0].hasOwnProperty("user_id")
                  ? this.getAnswerText(rowData[0].user_id, questionId)
                  : ""}
              </div>
            ),
          };
          columns.push(columnObj);
          this.state.questionColumnsObjectMap.set(questionId, columnObj);
        }
        this.setState({
          userDetailTableData: data.results,
          userDetailTableOverview: data.overview,
          columns: columns,
          userTableLoading: false,
        });
        this.userDetailTableKey++;
      });
  };

  private handleUserSurveyDetailsLinkClick = (userId: number) => {
    let answerDetailArray: any = this.props.allUserAnswers.filter(
      (answerObj: any) => answerObj.user_id === userId
    );
    let userSurveyDetailTableData: any = [];
    let questionIdSet: Set<number> = new Set();
    let sNo: number = 1;
    for (let i = 0; i < answerDetailArray.length; i++) {
      let questionId: number = answerDetailArray[i].question_id;
      if (questionIdSet.has(questionId)) {
        continue;
      }

      let questionObj: any =
        this.props.questionVsAnswerMap.get(questionId).question;

      userSurveyDetailTableData.push({
        sNo: sNo++,
        questionType: questionObj.question_type,
        questionText: questionObj.question_text,
        answerText: this.getAnswerText(userId, questionId),
      });
      questionIdSet.add(questionId);
    }

    let component: any = (
      <UserSurveyDetailsDialog
        key={userId}
        userId={userId}
        tableData={userSurveyDetailTableData}
        answerTableSettings={this.props.answerTableSettings}
        handleUserSurveyDetailsDialogClose={(event: any) => {
          this.handleUserSurveyDetailsDialogClose(userId);
        }}
      />
    );
    this.state.userIdVsSurveyDetailsDialogs.set(userId, component);
    this.setState({});
  };

  private handleUserSurveyDetailsDialogClose = (userId: number) => {
    this.state.userIdVsSurveyDetailsDialogs.delete(userId);
    this.setState({});
  };

  private scrollToDiv = (divId: string) => {
    const tableElement = document
      .getElementById("answerDetailsDialog")
      ?.getElementsByClassName("gelpCx")[1];
    const questionColumnElement = document.getElementById(divId);
    if (
      tableElement !== null &&
      questionColumnElement !== null &&
      tableElement !== undefined
    ) {
      var questionColumnRect = questionColumnElement.getBoundingClientRect();
      var tableElementColumnRect = tableElement.getBoundingClientRect();
      tableElement.scrollLeft +=
        questionColumnRect.left - tableElementColumnRect.left - 300;
    }
  };

  private getQuestionColumnContent = (
    questionId: number,
    questionOrderId: number
  ): any => {
    return (
      <div
        data-html={true}
        data-tip={
          this.props.questionVsAnswerMap.get(questionId).question
            .question_text +
          " <div>" +
          QUESTION_TYPE_LABEL[
            this.props.questionVsAnswerMap.get(questionId).question
              .question_type
          ] +
          "</div>"
        }
      >
        <ReactTooltip
          type="dark"
          effect="solid"
          html={true}
          multiline={true}
          className="tooltip-word-break"
        />
        <i
          id={`questioncolumn${questionId}`}
          className={`fa-solid pointer text-primary ${
            this.state.questionColumnsShowValMap.get(questionId)
              ? "fa-eye"
              : "fa-eye-slash"
          }`}
          onClick={(event: any) => {
            let newStatus: boolean =
              !this.state.questionColumnsShowValMap.get(questionId);
            this.state.questionColumnsShowValMap.set(questionId, newStatus);
            this.state.questionColumnsObjectMap.get(questionId).name = (
              <div>
                {this.getQuestionColumnContent(questionId, questionOrderId)}
              </div>
            );
            this.state.questionColumnsObjectMap.get(questionId).width =
              newStatus ? "200px" : "70px";
            this.setState({});
            this.userDetailTableKey++;
            this.scrollToPosition = "questioncolumn" + questionId;
          }}
        ></i>
        <br />
        {"Q" + questionOrderId}
      </div>
    );
  };

  private getAnswerText = (userId: number, questionId: number): any => {
    let valObjArray: any = this.state.questionIdVsUserIdVsAnswers
      .get(userId)
      .get(questionId);
    let retElements = [];
    let questionType: string =
      this.props.questionVsAnswerMap.get(questionId).question.question_type;
    if (questionType === "single_punch") {
      retElements.push(
        <span className="badge badge-primary white-space-br mb-1 mr-2">
          {this.getSelectedAnswerText(questionId, valObjArray[0].answer_id)}
        </span>
      );
    } else if (questionType === "multi_punch") {
      for (let i = 0; i < valObjArray.length; i++) {
        retElements.push(
          <span className="badge badge-success white-space-br mb-1 mr-2">
            {this.getSelectedAnswerText(questionId, valObjArray[i].answer_id)}
          </span>
        );
      }
    } else if (questionType === "GEO_IP_LAT_LNG") {
      let coordinate: any = JSON.parse(valObjArray[0].answer_json);
      retElements.push(
        <div className="pt-1 pb-1">
          <span className="badge badge-warning white-space-br mb-1 mr-2">
            {coordinate.latitude}
          </span>
          <span className="badge badge-warning white-space-br mb-1 mr-2">
            {coordinate.longitude}
          </span>
        </div>
      );
    } else if (questionType === "open_ended") {
      retElements.push(valObjArray[0].answer_text);
    }
    return retElements;
  };

  private getSelectedAnswerText = (
    questionId: number,
    answerId: number
  ): string => {
    return this.props.questionVsAnswerMap
      .get(questionId)
      .answerMap.get(answerId).selectedAnswerText;
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <div id="userSurveyDetailsDialogDiv">
              {Array.from(this.state.userIdVsSurveyDetailsDialogs.values())}
            </div>
            <Modal.Title>
              {this.props.questionVsAnswerMap.get(
                this.props.selectedQuestionId
              ) !== undefined
                ? this.props.questionVsAnswerMap.get(
                    this.props.selectedQuestionId
                  ).question.question_text +
                  " (" +
                  QUESTION_TYPE_LABEL[
                    this.props.questionVsAnswerMap.get(
                      this.props.selectedQuestionId
                    ).question.question_type
                  ] +
                  ")"
                : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="answerDetailsDialog">
            {this.props.answerTableData !== null &&
            this.props.questionVsAnswerEntry !== null ? (
              <SurveyTableComponent
                invocationMode={INVOCATION_MODE_ANSWER_DETAIL}
                hideChangeFilterColumn={
                  this.props.invocationMode === INVOCATION_MODE_ANSWER
                }
                answerDetailsNavigation={false}
                answerTableSettings={this.props.answerTableSettings}
                showTable={true}
                tableData={this.props.tableData}
                questionVsAnswerEntry={this.props.questionVsAnswerEntry}
                handleSelectChange={this.props.handleSelectChange}
                showPagination={
                  this.props.tableData != undefined &&
                  this.props.tableData.length > 1
                }
              />
            ) : null}
            <hr></hr>
            {this.state.userDetailTableData !== null &&
            this.state.userDetailTableData.length > 0 ? (
              <DataTable
                key={this.userDetailTableKey}
                paginationTotalRows={
                  this.state.userDetailTableOverview.number_of_users
                }
                progressPending={this.state.userTableLoading}
                paginationPerPage={this.state.pagination.rowsPerPage}
                paginationDefaultPage={this.state.pagination.currentPage}
                paginationRowsPerPageOptions={
                  this.props.answerTableSettings.rowsPerPageOptions
                }
                columns={this.state.columns}
                data={this.state.userDetailTableData}
                pagination
                paginationServer
                onChangeRowsPerPage={(
                  currentRowsPerPage: number,
                  currentPage: number
                ) => {
                  this.state.pagination.rowsPerPage = currentRowsPerPage;
                  this.state.pagination.currentPage = 1;
                  this.loadData();
                }}
                onChangePage={(page: number, totalRows: number) => {
                  this.state.pagination.currentPage = page;
                  this.loadData();
                }}
              />
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                this.props.onHide();
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                this.props.onHide();
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
