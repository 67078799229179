export const LOCAL_HOST_DOMAINS = ["localhost","bluecacadu.dev","192.168.29.85","lasivtech.com"];
export const TEST_HASH_VALUE = "8b9f8e822d20f62f3552742c96d46bdf";

export const TEST_HASH_PARAM = () => {
  let hostName: string = window.location.hostname.toLowerCase();
  let testHashParam: string = "";
  if (LOCAL_HOST_DOMAINS.filter((val) => hostName.includes(val)).length > 0) {
    testHashParam = "&testHash=" + TEST_HASH_VALUE;
  }

  return testHashParam;
};
