import { SurveyBarChartComponent } from './SurveyBarChartComponent';
import { SurveyDonutChartComponent } from './SurveyDonutChartComponent';

const SurveyGraphComponent = (props?: any) => {
    return (<div className='p-2'>
        <div className="">
            <div className="">
                <div className='mb-3'>
                    {props.showBarChart && props.consolidatedData ?
                        <SurveyBarChartComponent
                            index={props.index}
                            barData={props.consolidatedData.numberOfPeopleAnsweredArray}
                        >
                        </SurveyBarChartComponent>
                        : null}
                </div>
                <div className='mb-3'>
                    {props.showPieChart && props.consolidatedData ?
                        <SurveyDonutChartComponent
                            index={props.index}
                            donutData={props.consolidatedData.numberOfPeopleAnsweredArray}>
                        </SurveyDonutChartComponent>
                        : null}
                </div>
            </div>
        </div>
    </div>)

}

export default SurveyGraphComponent;