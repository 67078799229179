import React from "react";
import Modal from "react-bootstrap/Modal";
import Switch from "@mui/material/Switch";
import Button from "react-bootstrap/Button";
import {
  SETTING_ANSWER_TABLE_SETTINGS_LABEL,
  SETTING_DIAGRAM_LABEL,
  SETTING_DISPLAY_FILTERED_VALUE_LABEL,
  SETTING_ROWS_PER_PAGE_LABEL,
  SETTING_DISPLAY_SAME_PAGE_ALL_COLUMNS,
  GLOBAL_SCROLL_BUTTON,
  SETTINGS_DIALOG_TITLE
} from "../../common/DisplayMsgAndLabelConst";
export class SettingsDialog extends React.Component<any> {
  state = {
    displayFilteredValueInDiagram:
      this.props.settings.diagram.displayFilteredValue,
    rowsPerPage: this.props.settings.answerTable.rowsPerPage,
    displaySamePageAllColumns: this.props.settings.answerTable.displaySamePageAllColumns,
  };

  handleDisplayFilterChange = () => {
    this.state.displayFilteredValueInDiagram =
      !this.state.displayFilteredValueInDiagram;
    this.setState({})
  };

  handleRowPerPageChange = (value: any) => {
    this.state.rowsPerPage = value;
    this.setState({})
  };

  updateSettings = () => {
    this.props.onUpdateSettings({
      displayFilteredValueInDiagram: this.state.displayFilteredValueInDiagram,
      rowsPerPage: this.state.rowsPerPage,
      displaySamePageAllColumns:this.state.displaySamePageAllColumns
    });
  };

  handleDisplaySamePageAllColumns = () => {
    this.state.displaySamePageAllColumns =
      !this.state.displaySamePageAllColumns;
    this.setState({})
  };

  render() {
    return (
      <Modal
        show={this.props.showSettingModal}
        onHide={this.props.handleSettingsToggle}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{SETTINGS_DIALOG_TITLE()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <h5>{SETTING_ANSWER_TABLE_SETTINGS_LABEL()}</h5>
            <label className="col-sm-6 col-form-label col-form-label-sm">
              {SETTING_ROWS_PER_PAGE_LABEL()}
            </label>
            <div className="col-sm-6">
              <select
                className="form-select"
                aria-label="Default select example"
                value={this.state.rowsPerPage}
                onChange={(e) => this.handleRowPerPageChange(e.target.value)}
              >
                {this.props.settings.answerTable.rowsPerPageOptions.map(
                  (value: any) => (
                    <option key={value} value={value}>{value}</option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className="form-group row mt-4">
              <label className="col-sm-6 col-form-label col-form-label-sm">
                {SETTING_DISPLAY_SAME_PAGE_ALL_COLUMNS()}
              </label>
              <div className="col-sm-6">
                <Switch
                  color="primary"
                  checked={this.state.displaySamePageAllColumns}
                  onChange={this.handleDisplaySamePageAllColumns}
                />
              </div>
          </div>
          <div className="form-group row mt-5">
            <h5>{SETTING_DIAGRAM_LABEL()}</h5>
            <label className="col-sm-6 col-form-label col-form-label-sm">
              {SETTING_DISPLAY_FILTERED_VALUE_LABEL()}
            </label>
            <div className="col-sm-6">
              <Switch
                color="primary"
                checked={this.state.displayFilteredValueInDiagram}
                onChange={this.handleDisplayFilterChange}
              />
            </div>
          </div>
          <div className="form-group row mt-4">
              <label className="col-sm-6 col-form-label col-form-label-sm">
                {GLOBAL_SCROLL_BUTTON()}
              </label>
              <div className="col-sm-6">
                <Switch
                  color="primary"
                  checked={this.props.switchStatus}
                  onChange={this.props.handleSwitchChange}
                />
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              this.updateSettings();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
