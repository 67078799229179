import React, { forwardRef } from "react";
import DataTable from "react-data-table-component";
import {
  SURVEY_QUICKVIEW_TABLE_NO_DATA_ERROR,
} from "../common/DisplayMsgAndLabelConst";

export class SurveyQuickViewTableComponent extends React.Component<any> {
  scrollToQuestion = (questionId: any) => {
    this.props.scrollToQuestion(questionId);
  };

  onSurveyInfoRowComponentClick = (questionId: any) => {
    this.props.onSurveyInfoRowComponentClick(questionId);
  };

  render() {
    return (
      <div id="surveyQuickViewTable">
        <DataTable
          pagination={false}
          noDataComponent={SURVEY_QUICKVIEW_TABLE_NO_DATA_ERROR()}
          columns={[
            {
              sortable: true,
              name: "Answer",
              width:"300px",
              cell: (rowdata: any) => (
                <div className="surveyQuickQuestion">
                  <span
                    className="pointer"
                    onClick={(e) => {
                      this.props.scrollToQuestion(rowdata.questionId);
                    }}
                  >
                    {rowdata.questionText}
                  </span>
                </div>
              ),
            },
            {
              sortable: true,
              name: "Filter",
              cell: (rowdata: any) => (
                <div>
                  <span
                    className="pointer"
                    onClick={(e) => {
                      this.props.onSurveyInfoRowComponentClick(
                        rowdata.questionId
                      );
                    }}
                  >
                    {rowdata.selectedAnswersText}
                  </span>
                </div>
              ),
            },
          ]}
          data={this.props.quickViewData}
        />
      </div>
    );
  }
}
