import React from "react";
import { AnswerFilterBar } from "../sidenav/AnswerFilterBar";

export class SideNavBarList extends React.Component<any> {

  private answerFilterBarChildRef:any;

  constructor(props:any) {
    super(props);
    this.answerFilterBarChildRef = React.createRef();
  }

  handleAnswerFilterSelectChange = (questionId:any, answerId:any) => {
    this.props.handleAnswerFilterSelectChange(questionId, answerId, this.props.stateValue.selectedColumnId);
  }

  handleResetAnswerSelection = (questionId:number, checked:boolean) => {
    this.props.handleResetAnswerSelection(questionId, this.props.stateValue.selectedColumnId, checked);
  }

  refreshUI = () => {
    let answerFilterBar:AnswerFilterBar = this.answerFilterBarChildRef.current;
    answerFilterBar.refreshOptionsSelectedStatusMaps();
  }

  render() {
  return (
    <div
      ref={this.props.innerRef}
      id="kt_engage_demos"
      className={`bg-body drawer drawer-end ${
        this.props.stateValue.isSideNavBarOpen ? "drawer-on" : ""
      }`}
      style={{ width: "415px" }}
    >
      <div className="card shadow-none rounded-0 w-100">
        <div className="card-header" id="kt_engage_demos_header">
          <h3 className="card-title fw-bolder text-gray-700"></h3>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6"
              id="kt_engage_demos_close"
              onClick={() => this.props.handleCloseSideNavBarList()}
            >
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>

        <div className="card-body" id="kt_engage_demos_body">
          <div
            id="kt_explore_scroll"
            className="scroll-y me-n5 pe-5"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_engage_demos_body"
            data-kt-scroll-dependencies="#kt_engage_demos_header"
            data-kt-scroll-offset="5px"
          >
            <div className="mb-0">
              <div className="navbar-wrapper content-main overflow-auto">
                <div className="navbar-content scroll-div">
                  {this.props.stateValue.columnVsFilterMap &&
                  this.props.stateValue.columnVsQuestionVsAnswerMap &&
                  this.props.stateValue.selectedColumnId !== -1 ? (
                    <AnswerFilterBar
                      ref={this.answerFilterBarChildRef}
                      toggleQuestionAccordionHandler={
                        this.props.toggleQuestionAccordionHandler
                      }
                      selectedFilterAccordionQuestionId={
                        this.props.stateValue.selectedFilterAccordionQuestionId
                      }
                      selectedFilter={this.props.stateValue.columnVsFilterMap.get(
                        this.props.stateValue.selectedColumnId
                      )}
                      questionVsAnswerMap={this.props.stateValue.columnVsQuestionVsAnswerMap.get(
                        this.props.stateValue.selectedColumnId
                      )}
                      handleSelectChange={this.handleAnswerFilterSelectChange}
                      handleResetAnswerSelection={this.handleResetAnswerSelection}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                 }
};


