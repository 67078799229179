import { DisplayMsgUtil } from "./DisplayMsgUtil";

export const INVALID_PROJECT_ID_IN_QUERY_PARAM =():string =>{
  return DisplayMsgUtil.getDisplayValue("INVALID_PROJECT_ID_IN_QUERY_PARAM");
}
export const QUESTION_DATA_PARSE_ERROR = ():string =>{
  return DisplayMsgUtil.getDisplayValue("QUESTION_DATA_PARSE_ERROR");
}
export const NO_QUESTION_DATA_FOUND = ():string =>{
  return DisplayMsgUtil.getDisplayValue("NO_QUESTION_DATA_FOUND");
}
export const ANSWER_JSON_PARSE_ERROR = ():string =>{
  return DisplayMsgUtil.getDisplayValue("ANSWER_JSON_PARSE_ERROR");
}
export const NO_ANSWER_DATA_FOUND = ():string =>{
  return DisplayMsgUtil.getDisplayValue("NO_ANSWER_DATA_FOUND");
}
export const MIN_COULUMN_ERROR = ():string =>{
  return DisplayMsgUtil.getDisplayValue("MIN_COULUMN_ERROR");
}
export const ADD_COLUMN_NEW_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("ADD_COLUMN_NEW_BUTTON");
}
export const ADD_COLUMN_DUPLICATE_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("ADD_COLUMN_DUPLICATE_BUTTON");
}
  export const GLOBAL_SCROLL_BUTTON = ():string =>{
    return DisplayMsgUtil.getDisplayValue("GLOBAL_SCROLL_BUTTON");
  }
  export const SETTINGS_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SETTINGS_BUTTON");
  }
  export const FILTER_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("FILTER_BUTTON");
  }
  export const COLLAPSE_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("COLLAPSE_BUTTON");
  }
export const FREE_TEXT_ANSWER_TABLE_SNO_COLUMN = ():string =>{
  return DisplayMsgUtil.getDisplayValue("FREE_TEXT_ANSWER_TABLE_SNO_COLUMN");
}

export const FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN = ():string =>{
  return DisplayMsgUtil.getDisplayValue("FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN");
} 

export const SURVEY_GRAPH_BAR_CHART_LABEL = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SURVEY_GRAPH_BAR_CHART_LABEL");
}
export const SURVEY_TABLE_SNO_COLUMN = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SURVEY_TABLE_SNO_COLUMN");
}
export const SURVEY_TABLE_ANSWER_COLUMN = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SURVEY_TABLE_ANSWER_COLUMN");
}
export const SURVEY_TABLE_PERCENTAGE_COLUMN = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SURVEY_TABLE_PERCENTAGE_COLUMN");
}
export const SURVEY_TABLE_NUMBER_COLUMN = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SURVEY_TABLE_NUMBER_COLUMN");
}
export const QUESTION_TYPE_LABEL:any = {
  single_punch: "Single Punch",
  multi_punch: "Multi Punch",
  GEO_IP_LAT_LNG: "Geo",
  open_ended: "Free Text",
};
export const CHART_COLOR_CODE: any = [
	"#EC6B56", "#FFC154", "#47B39C", "#2f4b7c", "#ffa600",
	'#4D8000', "#f95d6a", '#3366E6', '#FFB399', "#665191",
	"#d45087", '#FF33FF', '#FFFF99', '#9900B3', "#003f5c",
	"#ff7c43", '#4D80CC', "#488f31", '#E6B333', '#00B3E6',
	'#999966', '#99FF99', '#B34D4D', '#6666FF', '#4DB380',
	'#80B300', '#E6B3B3', '#6680B3', '#66991A', '#809900',
	'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#00E680',
	'#66994D', '#B366CC', '#B33300', '#CC80CC', '#FF6633',
	'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
	'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#33FFCC',
	'#809980', '#E6FF80', '#1AFF33', '#999933', "#a05195",
	'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#4D8066',
	'#E64D66', '#99E6E6', '#FF4D4D',
]
export const SAVE_ANALYSIS_BUTTON = ():string =>{
return DisplayMsgUtil.getDisplayValue("SAVE_ANALYSIS_BUTTON");
}

export const LOAD_ANALYSIS_BUTTON = ():string =>{
return DisplayMsgUtil.getDisplayValue("LOAD_ANALYSIS_BUTTON");
}

export const LOAD_ANALYSIS_SUCCESS_MSG =():string =>{
return DisplayMsgUtil.getDisplayValue("LOAD_ANALYSIS_SUCCESS_MSG");
}

export const FILTER_DATA_SAVE_MSG = ():string =>{
  return DisplayMsgUtil.getDisplayValue("FILTER_DATA_SAVE_MSG");
}

export const ATLEAST_ONE_ANSWER_MSG = ():string =>{
  return DisplayMsgUtil.getDisplayValue("ATLEAST_ONE_ANSWER_MSG");
}

export const SETTING_ANSWER_TABLE_SETTINGS_LABEL = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SETTING_ANSWER_TABLE_SETTINGS_LABEL");
}
export const SETTING_ROWS_PER_PAGE_LABEL = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SETTING_ROWS_PER_PAGE_LABEL");
}
export const SETTING_DIAGRAM_LABEL = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SETTING_DIAGRAM_LABEL");
}
export const SETTING_DISPLAY_FILTERED_VALUE_LABEL = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SETTING_DISPLAY_FILTERED_VALUE_LABEL");
}
export const SETTING_DISPLAY_SAME_PAGE_ALL_COLUMNS = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SETTING_DISPLAY_SAME_PAGE_ALL_COLUMNS");
}

export const SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR");
}
export const SAVE_ANALYSIS_EMPTY_ERROR = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SAVE_ANALYSIS_EMPTY_ERROR");
}
export const LOAD_ANALYSIS_ERROR = ():string =>{
  return DisplayMsgUtil.getDisplayValue("LOAD_ANALYSIS_ERROR");
}

export const LOAD_SURVEY_DIALOG_TITLE = ():string => {
  return DisplayMsgUtil.getDisplayValue("LOAD_SURVEY_DIALOG_TITLE");
} 

export const LOAD_SURVEY_DIALOG_DELETE_ALL_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("LOAD_SURVEY_DIALOG_DELETE_ALL_BUTTON");
} 

export const LOAD_SURVEY_DIALOG_CREATE_NEW_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("LOAD_SURVEY_DIALOG_CREATE_NEW_BUTTON");
}
export const LOAD_SURVEY_DIALOG_CANCEL_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("LOAD_SURVEY_DIALOG_CANCEL_BUTTON");
}
export const LOAD_SURVEY_DIALOG_LOAD_BUTTON = ():string =>{
  return DisplayMsgUtil.getDisplayValue("LOAD_SURVEY_DIALOG_LOAD_BUTTON");
}
export const LOAD_SURVEY_DIALOG_CONFIRMATION_TITLE = ():string =>{
  return DisplayMsgUtil.getDisplayValue("LOAD_SURVEY_DIALOG_CONFIRMATION_TITLE");
}
export const LOAD_SURVEY_DIALOG_CONFIRMATION_TEXT = ():string =>{
  return DisplayMsgUtil.getDisplayValue("LOAD_SURVEY_DIALOG_CONFIRMATION_TEXT");
}

export const QUESTIONS_LIST_DIALOG_TITLE = ():string =>{
  return DisplayMsgUtil.getDisplayValue("QUESTIONS_LIST_DIALOG_TITLE");
}

export const SAVE_SURVEY_DIALOG_TITLE = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SAVE_SURVEY_DIALOG_TITLE");
}

export const SAVE_SURVEY_DIALOG_ANALYSIS_NAME_LABEL = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SAVE_SURVEY_DIALOG_ANALYSIS_NAME_LABEL");
}
export const SAVE_SURVEY_DIALOG_SAVE_LABEL = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SAVE_SURVEY_DIALOG_SAVE_LABEL");
}
export const SAVE_SURVEY_DIALOG_CLOSE_LABEL = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SAVE_SURVEY_DIALOG_CLOSE_LABEL");
}

export const SETTINGS_DIALOG_TITLE = ():string =>{
  return DisplayMsgUtil.getDisplayValue("SETTINGS_DIALOG_TITLE");
}

export const USER_SURVEY_DETAILS_DIALOG_TITLE = ():string =>{
  return DisplayMsgUtil.getDisplayValue("USER_SURVEY_DETAILS_DIALOG_TITLE");
}

export const DASBOARD_FILTERS_TITLE = ():string =>{
  return DisplayMsgUtil.getDisplayValue("DASBOARD_FILTERS_TITLE");
}
export const DASBOARD_SAVE_SUCCESS_BEGINNING_TEXT = ():string =>{
  return DisplayMsgUtil.getDisplayValue("DASBOARD_SAVE_SUCCESS_BEGINNING_TEXT");
}
export const DASBOARD_SAVE_SUCCESS_END_TEXT = ():string =>{
  return DisplayMsgUtil.getDisplayValue("DASBOARD_SAVE_SUCCESS_END_TEXT");
}
export const DASBOARD_LOAD_ANALYSIS_DATA_ERROR = ():string =>{
  return DisplayMsgUtil.getDisplayValue("DASBOARD_LOAD_ANALYSIS_DATA_ERROR");
}
export const DASBOARD_LOAD_SUCCESS_BEGINNING_TEXT = ():string =>{
  return DisplayMsgUtil.getDisplayValue("DASBOARD_LOAD_SUCCESS_BEGINNING_TEXT");
}
export const DASBOARD_LOAD_SUCCESS_END_TEXT = ():string =>{
  return DisplayMsgUtil.getDisplayValue("DASBOARD_LOAD_SUCCESS_END_TEXT");
}
export const DASBOARD_NEW_COMPARISION_CREATED_SUCCESS = ():string =>{
   return DisplayMsgUtil.getDisplayValue("DASBOARD_NEW_COMPARISION_CREATED_SUCCESS");
}

export const SURVEY_QUICKVIEW_TABLE_NO_DATA_ERROR= ():string =>{
  return DisplayMsgUtil.getDisplayValue("SURVEY_QUICKVIEW_TABLE_NO_DATA_ERROR");
}

export const ANSWER_FILTER_BAR_SEARCH_PLACEHOLDER= ():string =>{
  return DisplayMsgUtil.getDisplayValue("ANSWER_FILTER_BAR_SEARCH_PLACEHOLDER");
}

export const TOOLBAR_SCROLL_UP_BUTTON_TEXT= ():string =>{
  return DisplayMsgUtil.getDisplayValue("TOOLBAR_SCROLL_UP_BUTTON_TEXT");
}
export const TOOLBAR_SCROLL_DOWN_BUTTON_TEXT= ():string =>{
  return DisplayMsgUtil.getDisplayValue("TOOLBAR_SCROLL_DOWN_BUTTON_TEXT");
}
export const TOOLBAR_SCROLL_TO_QUESTION_BUTTON_TEXT= ():string =>{
  return DisplayMsgUtil.getDisplayValue("TOOLBAR_SCROLL_TO_QUESTION_BUTTON_TEXT");
}