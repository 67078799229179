import { INVALID_PROJECT_ID_IN_QUERY_PARAM,
  QUESTION_DATA_PARSE_ERROR,
  NO_QUESTION_DATA_FOUND,
  ANSWER_JSON_PARSE_ERROR,
  NO_ANSWER_DATA_FOUND,
  MIN_COULUMN_ERROR,
  ADD_COLUMN_NEW_BUTTON,
  ADD_COLUMN_DUPLICATE_BUTTON,
  GLOBAL_SCROLL_BUTTON,
  SETTINGS_BUTTON,
  FILTER_BUTTON,
  COLLAPSE_BUTTON,
  FREE_TEXT_ANSWER_TABLE_SNO_COLUMN,
  FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN,
  SURVEY_GRAPH_BAR_CHART_LABEL,
  SURVEY_TABLE_SNO_COLUMN,
  SURVEY_TABLE_ANSWER_COLUMN,
  SURVEY_TABLE_PERCENTAGE_COLUMN,
  SURVEY_TABLE_NUMBER_COLUMN,
  SAVE_ANALYSIS_BUTTON,
  LOAD_ANALYSIS_BUTTON,
  LOAD_ANALYSIS_SUCCESS_MSG,
  FILTER_DATA_SAVE_MSG,
  ATLEAST_ONE_ANSWER_MSG,
  SETTING_ANSWER_TABLE_SETTINGS_LABEL,
  SETTING_ROWS_PER_PAGE_LABEL,
  SETTING_DIAGRAM_LABEL,
  SETTING_DISPLAY_FILTERED_VALUE_LABEL,
  SETTING_DISPLAY_SAME_PAGE_ALL_COLUMNS,
  SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR,
  SAVE_ANALYSIS_EMPTY_ERROR,
  LOAD_ANALYSIS_ERROR,
  LOAD_SURVEY_DIALOG_TITLE,
  LOAD_SURVEY_DIALOG_DELETE_ALL_BUTTON,
  LOAD_SURVEY_DIALOG_CREATE_NEW_BUTTON,
  LOAD_SURVEY_DIALOG_CANCEL_BUTTON,
  LOAD_SURVEY_DIALOG_LOAD_BUTTON,
  LOAD_SURVEY_DIALOG_CONFIRMATION_TITLE,
  LOAD_SURVEY_DIALOG_CONFIRMATION_TEXT,
  QUESTIONS_LIST_DIALOG_TITLE,
  SAVE_SURVEY_DIALOG_TITLE,
  SAVE_SURVEY_DIALOG_ANALYSIS_NAME_LABEL,
  SAVE_SURVEY_DIALOG_SAVE_LABEL,
  SAVE_SURVEY_DIALOG_CLOSE_LABEL,
  SETTINGS_DIALOG_TITLE,
  USER_SURVEY_DETAILS_DIALOG_TITLE,
  DASBOARD_FILTERS_TITLE,
  DASBOARD_SAVE_SUCCESS_BEGINNING_TEXT,
  DASBOARD_SAVE_SUCCESS_END_TEXT,
  DASBOARD_LOAD_ANALYSIS_DATA_ERROR,
  DASBOARD_LOAD_SUCCESS_BEGINNING_TEXT,
  DASBOARD_LOAD_SUCCESS_END_TEXT,
  DASBOARD_NEW_COMPARISION_CREATED_SUCCESS,
  SURVEY_QUICKVIEW_TABLE_NO_DATA_ERROR,
  ANSWER_FILTER_BAR_SEARCH_PLACEHOLDER,
  TOOLBAR_SCROLL_UP_BUTTON_TEXT,
  TOOLBAR_SCROLL_DOWN_BUTTON_TEXT,
  TOOLBAR_SCROLL_TO_QUESTION_BUTTON_TEXT  
 } from "../common/DisplayMsgAndLabelsEng";

export class DisplayMsgUtil {
  private static instance: DisplayMsgUtil;
  private displayKeyValueMap: Map<String, String> = new Map();

  public static initInstance = (callbackFn: Function): void => {
    DisplayMsgUtil.instance = new DisplayMsgUtil();
    DisplayMsgUtil.instance.initDisplayKeyValueMap(callbackFn);
  };

  private initDisplayKeyValueMap = (callbackFn: Function) => {
    this.displayKeyValueMap = new Map();
    this.loadEnglishValues();
    fetch("https://askv2.makeopinion.com/?page=text-translations")
      .then((response) => {
        try {
          return response.json();
        } catch (error) {}
      })
      .then((messages) => {
        if (messages !== null) {
          for (let message in messages) {
            this.displayKeyValueMap.set(message, messages[message]);
          }
        }
        callbackFn();
      });
  };

  public static getDisplayValue = (key: string): string => {
    if (DisplayMsgUtil.instance === null || DisplayMsgUtil.instance === undefined) {
      return key + " - Initialization not yet completed";
    }
    //console.log(DisplayMsgUtil.instance.displayKeyValueMap);
    let displayValue: any = DisplayMsgUtil.instance.displayKeyValueMap.get(key);
    return displayValue || key + " - Key Not exists";
  };

  loadEnglishValues= () =>{
    this.displayKeyValueMap.set("INVALID_PROJECT_ID_IN_QUERY_PARAM",INVALID_PROJECT_ID_IN_QUERY_PARAM);
    this.displayKeyValueMap.set("QUESTION_DATA_PARSE_ERROR",QUESTION_DATA_PARSE_ERROR);
    this.displayKeyValueMap.set("NO_QUESTION_DATA_FOUND",NO_QUESTION_DATA_FOUND);
    this.displayKeyValueMap.set("ANSWER_JSON_PARSE_ERROR",ANSWER_JSON_PARSE_ERROR);
    this.displayKeyValueMap.set("NO_ANSWER_DATA_FOUND",NO_ANSWER_DATA_FOUND);
    this.displayKeyValueMap.set("MIN_COULUMN_ERROR",MIN_COULUMN_ERROR);
    this.displayKeyValueMap.set("ADD_COLUMN_NEW_BUTTON",ADD_COLUMN_NEW_BUTTON);
    this.displayKeyValueMap.set("ADD_COLUMN_DUPLICATE_BUTTON",ADD_COLUMN_DUPLICATE_BUTTON);
    this.displayKeyValueMap.set("GLOBAL_SCROLL_BUTTON",GLOBAL_SCROLL_BUTTON);
    this.displayKeyValueMap.set("SETTINGS_BUTTON",SETTINGS_BUTTON);
    this.displayKeyValueMap.set("FILTER_BUTTON",FILTER_BUTTON);
    this.displayKeyValueMap.set("COLLAPSE_BUTTON",COLLAPSE_BUTTON);
    this.displayKeyValueMap.set("FREE_TEXT_ANSWER_TABLE_SNO_COLUMN",FREE_TEXT_ANSWER_TABLE_SNO_COLUMN);
    this.displayKeyValueMap.set("FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN",FREE_TEXT_ANSWER_TABLE_ANSWER_COLUMN);
    this.displayKeyValueMap.set("SURVEY_GRAPH_BAR_CHART_LABEL",SURVEY_GRAPH_BAR_CHART_LABEL);
    this.displayKeyValueMap.set("SURVEY_TABLE_SNO_COLUMN",SURVEY_TABLE_SNO_COLUMN);
    this.displayKeyValueMap.set("SURVEY_TABLE_ANSWER_COLUMN",SURVEY_TABLE_ANSWER_COLUMN);
    this.displayKeyValueMap.set("SURVEY_TABLE_PERCENTAGE_COLUMN",SURVEY_TABLE_PERCENTAGE_COLUMN);
    this.displayKeyValueMap.set("SURVEY_TABLE_NUMBER_COLUMN",SURVEY_TABLE_NUMBER_COLUMN);
    this.displayKeyValueMap.set("SAVE_ANALYSIS_BUTTON",SAVE_ANALYSIS_BUTTON);
    this.displayKeyValueMap.set("LOAD_ANALYSIS_BUTTON",LOAD_ANALYSIS_BUTTON);
    this.displayKeyValueMap.set("LOAD_ANALYSIS_SUCCESS_MSG",LOAD_ANALYSIS_SUCCESS_MSG);
    this.displayKeyValueMap.set("FILTER_DATA_SAVE_MSG",FILTER_DATA_SAVE_MSG);
    this.displayKeyValueMap.set("ATLEAST_ONE_ANSWER_MSG",ATLEAST_ONE_ANSWER_MSG);
    this.displayKeyValueMap.set("SETTING_ANSWER_TABLE_SETTINGS_LABEL",SETTING_ANSWER_TABLE_SETTINGS_LABEL);
    this.displayKeyValueMap.set("SETTING_ROWS_PER_PAGE_LABEL",SETTING_ROWS_PER_PAGE_LABEL);
    this.displayKeyValueMap.set("SETTING_DIAGRAM_LABEL",SETTING_DIAGRAM_LABEL);
    this.displayKeyValueMap.set("SETTING_DISPLAY_FILTERED_VALUE_LABEL",SETTING_DISPLAY_FILTERED_VALUE_LABEL);
    this.displayKeyValueMap.set("SETTING_DISPLAY_SAME_PAGE_ALL_COLUMNS",SETTING_DISPLAY_SAME_PAGE_ALL_COLUMNS);
    this.displayKeyValueMap.set("SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR",SAVE_ANALYSIS_CHARACTER_LIMIT_ERROR);
    this.displayKeyValueMap.set("SAVE_ANALYSIS_EMPTY_ERROR",SAVE_ANALYSIS_EMPTY_ERROR);
    this.displayKeyValueMap.set("LOAD_ANALYSIS_ERROR",LOAD_ANALYSIS_ERROR);
    this.displayKeyValueMap.set("LOAD_SURVEY_DIALOG_TITLE",LOAD_SURVEY_DIALOG_TITLE);
    this.displayKeyValueMap.set("LOAD_SURVEY_DIALOG_DELETE_ALL_BUTTON",LOAD_SURVEY_DIALOG_DELETE_ALL_BUTTON);
    this.displayKeyValueMap.set("LOAD_SURVEY_DIALOG_CREATE_NEW_BUTTON",LOAD_SURVEY_DIALOG_CREATE_NEW_BUTTON);
    this.displayKeyValueMap.set("LOAD_SURVEY_DIALOG_CANCEL_BUTTON",LOAD_SURVEY_DIALOG_CANCEL_BUTTON);
    this.displayKeyValueMap.set("LOAD_SURVEY_DIALOG_LOAD_BUTTON",LOAD_SURVEY_DIALOG_LOAD_BUTTON);
    this.displayKeyValueMap.set("LOAD_SURVEY_DIALOG_CONFIRMATION_TITLE",LOAD_SURVEY_DIALOG_CONFIRMATION_TITLE);
    this.displayKeyValueMap.set("LOAD_SURVEY_DIALOG_CONFIRMATION_TEXT",LOAD_SURVEY_DIALOG_CONFIRMATION_TEXT);
    this.displayKeyValueMap.set("QUESTIONS_LIST_DIALOG_TITLE",QUESTIONS_LIST_DIALOG_TITLE);
    this.displayKeyValueMap.set("SAVE_SURVEY_DIALOG_TITLE",SAVE_SURVEY_DIALOG_TITLE);
    this.displayKeyValueMap.set("SAVE_SURVEY_DIALOG_ANALYSIS_NAME_LABEL",SAVE_SURVEY_DIALOG_ANALYSIS_NAME_LABEL);
    this.displayKeyValueMap.set("SAVE_SURVEY_DIALOG_SAVE_LABEL",SAVE_SURVEY_DIALOG_SAVE_LABEL);
    this.displayKeyValueMap.set("SAVE_SURVEY_DIALOG_CLOSE_LABEL",SAVE_SURVEY_DIALOG_CLOSE_LABEL);
    this.displayKeyValueMap.set("SETTINGS_DIALOG_TITLE",SETTINGS_DIALOG_TITLE);
    this.displayKeyValueMap.set("DASBOARD_FILTERS_TITLE",DASBOARD_FILTERS_TITLE);
    this.displayKeyValueMap.set("USER_SURVEY_DETAILS_DIALOG_TITLE",USER_SURVEY_DETAILS_DIALOG_TITLE);
    this.displayKeyValueMap.set("DASBOARD_SAVE_SUCCESS_BEGINNING_TEXT",DASBOARD_SAVE_SUCCESS_BEGINNING_TEXT);
    this.displayKeyValueMap.set("DASBOARD_SAVE_SUCCESS_END_TEXT",DASBOARD_SAVE_SUCCESS_END_TEXT);
    this.displayKeyValueMap.set("DASBOARD_LOAD_ANALYSIS_DATA_ERROR",DASBOARD_LOAD_ANALYSIS_DATA_ERROR);
    this.displayKeyValueMap.set("DASBOARD_LOAD_SUCCESS_BEGINNING_TEXT",DASBOARD_LOAD_SUCCESS_BEGINNING_TEXT);
    this.displayKeyValueMap.set("DASBOARD_LOAD_ANALYSIS_DATA_ERROR",DASBOARD_LOAD_ANALYSIS_DATA_ERROR);
    this.displayKeyValueMap.set("DASBOARD_LOAD_SUCCESS_END_TEXT",DASBOARD_LOAD_SUCCESS_END_TEXT);
    this.displayKeyValueMap.set("DASBOARD_NEW_COMPARISION_CREATED_SUCCESS",DASBOARD_NEW_COMPARISION_CREATED_SUCCESS);
    this.displayKeyValueMap.set("SURVEY_QUICKVIEW_TABLE_NO_DATA_ERROR",SURVEY_QUICKVIEW_TABLE_NO_DATA_ERROR);
    this.displayKeyValueMap.set("ANSWER_FILTER_BAR_SEARCH_PLACEHOLDER",ANSWER_FILTER_BAR_SEARCH_PLACEHOLDER);
    this.displayKeyValueMap.set("TOOLBAR_SCROLL_UP_BUTTON_TEXT",TOOLBAR_SCROLL_UP_BUTTON_TEXT);
    this.displayKeyValueMap.set("TOOLBAR_SCROLL_DOWN_BUTTON_TEXT",TOOLBAR_SCROLL_DOWN_BUTTON_TEXT);
    this.displayKeyValueMap.set("TOOLBAR_SCROLL_TO_QUESTION_BUTTON_TEXT",TOOLBAR_SCROLL_TO_QUESTION_BUTTON_TEXT);
  }
}
