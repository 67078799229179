import ReactTooltip from "react-tooltip";
import React, { Component } from "react";
import {
  LOAD_ANALYSIS_BUTTON,
  SAVE_ANALYSIS_BUTTON,
  SETTINGS_BUTTON,
  TOOLBAR_SCROLL_UP_BUTTON_TEXT,
  TOOLBAR_SCROLL_DOWN_BUTTON_TEXT,
  TOOLBAR_SCROLL_TO_QUESTION_BUTTON_TEXT,
} from "../../modules/common/DisplayMsgAndLabelConst";

export class Toolbar extends React.Component<any> {
  scrollBottomOneStep = () => {
    this.props.upDownButtonClickHandler(true);
  };

  scrollTopOneStep = () => {
    this.props.upDownButtonClickHandler(false);
  };

  render() {
    return (
      <div>
        <div className="css-1c8uloi">
          <button
            data-tip={TOOLBAR_SCROLL_UP_BUTTON_TEXT()}
            type="button"
            className="css-6nf0j2"
            onClick={(e) => {
              this.scrollTopOneStep();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M7 14l5-5 5 5H7z"></path></svg>
          </button>
          <button
            data-tip={TOOLBAR_SCROLL_TO_QUESTION_BUTTON_TEXT()}
            type="button"
            className="css-6nf0j2"
            onClick={(e) => {
              this.props.handleToggleQuestionListModal();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"></path></svg>
          </button>
          <button
            data-tip={TOOLBAR_SCROLL_DOWN_BUTTON_TEXT()}
            type="button"
            className="css-6nf0j2"
            onClick={(e) => {
              this.scrollBottomOneStep();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M7 10l5 5 5-5H7z"></path></svg>
          </button>
          <div className="css-i0als3"></div>
          <button
            data-tip={LOAD_ANALYSIS_BUTTON()}
            type="button"
            className="css-6nf0j2"
            onClick={(e) => {
              this.props.toggleLoadAnalysis();
            }}
          >
            <i
              className={`fa-solid fa-folder-open fa-lg ${
                !this.props.isLoadAnalysisButtonEnabled
                  ? "text-grey"
                  : "text-white"
              }`}
            ></i>
          </button>
          <button
            data-tip={SAVE_ANALYSIS_BUTTON()}
            type="button"
            className="css-6nf0j2"
            onClick={(e) => {
              this.props.toggleSaveAnalysis();
            }}
          >
            <i className="fa-solid fa-floppy-disk text-white fa-lg"></i>
          </button>
          <div className="css-i0als3"></div>
          <button
            data-tip={SETTINGS_BUTTON()}
            type="button"
            className="css-6nf0j2"
            onClick={(e) => {
              this.props.handleSettingsToggle();
            }}
          >
            <i className="fa fa-cog text-white fa-lg"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default Toolbar;
